import Quill from 'quill';
import MentionBlot from 'editor/quill/formats/mention';

const Inline = Quill.import('blots/inline');

class AtwhoBlot extends Inline {
  static formats() {
    return true;
  }
}
AtwhoBlot.blotName = 'atwho';
AtwhoBlot.className = 'atwho-query';
AtwhoBlot.tagName = 'span';
Quill.register(AtwhoBlot);

class Editor {
  constructor(el, args) {
    this.$el = $(el);
    const defaults = {
      theme: 'snow',
      mentions: true,
      modules: {
        toolbar: {
          handlers: {
            image: () => this.selectLocalImage(),
          },
          container: [
            { header: '1' },
            'bold',
            'italic',
            'blockquote',
            'link',
            'image',
            'video',
          ],
        },
      },
    };
    this.options = Object.assign({}, defaults, el.dataset, args);
    this.quill = new Quill(el, this.options);

    if (this.options.mentions) {
      this.setupMentions();
    }
  }

  setupMentions() {
    const handler = '@';
    const mentionCache = {};

    this.$el.find('.ql-editor').atwho({
      at: handler,
      insertTpl: '${id}',
      displayTpl:
        '<li>' +
        '<img class="atwho__thumbnail" src="${avatar_url}" />' +
        '${name}' +
        '</li>',
      callbacks: {
        remoteFilter: (query, callback) => {
          if (query.length <= 2) {
            return callback([]);
          }
          // Cached resolver: only executes requests if they
          // have not been executed before and if they are not in progress.
          Promise.resolve(mentionCache[query]).then((response) => {
            if (response == void 0) {
              mentionCache[query] = $.get(
                `/api/internal/users.json?filter=at,identities&at=${query}`,
              );
            } else {
              callback(response);
            }
          });
        },
        beforeInsert: (mentionableId) => {
          const range = this.quill.getSelection(true);
          if (!range) {
            return;
          }

          const atIndex = this._lastIndexOf('@', range.index);
          const length = range.index - atIndex;

          this.quill.deleteText(atIndex, length);
          this.quill.insertEmbed(
            atIndex,
            'mention',
            mentionableId,
            Quill.sources.USER,
          );

          const followedBySpace = this.quill.getText(atIndex + 1, 1) == ' ';
          if (!followedBySpace) {
            // There is rendering quirk when newline goes straight after
            // mention blot. Workaround is to insert space, which also
            // improves editing experience.
            this.quill.insertText(atIndex + 1, ' ');
          }
          this.quill.setSelection(atIndex + 2);
        },
      },
    });

    const atwho = this.$el.find('.ql-editor').data('atwho');
    // By default at.js will try to insert HTML.
    // With Quill this is not possible and we handle it
    // ourselves using beforeInsert callback.
    // Thus we turn .insert into noop (because it raises exception).
    atwho.controllers[handler].insert = () => {};
  }

  selectLocalImage() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();
    input.onchange = () => {
      this._saveFileToServer(input.files[0]);
    };
  }

  _saveFileToServer(file) {
    if (!this.options.imageUploadUrl) {
      throw 'Image upload path is not configured. Cannot save to server.';
    }
    const formData = new FormData();
    formData.append('image', file);

    $.ajax(this.options.imageUploadUrl, {
      processData: false,
      contentType: false,
      data: formData,
      method: 'POST',
    })
      .done((response) => {
        this._insertToEditor(response.url);
      })
      .fail((response) => {
        alert(response.responseJSON.error);
      });
  }

  _insertToEditor(url) {
    const range = this.quill.getSelection();
    this.quill.insertEmbed(range.index, 'image', url);
  }

  getContents() {
    // It is not Quill's concern to provide HTML
    // thus they have removed getHTML() method.
    // Here we sort of rely on their private rendering of their Delta format.
    return this.quill.root.innerHTML;
  }

  // Cannot use quill.getText().lastIndexOf because
  // getText() returned value contains only text elements.
  // If content has any embeds they shift quills internal
  // character index thus making a mismatch.
  //
  // Here we use quill's methods to reimplement simple lastIndexOf.
  _lastIndexOf(symbol, length = this.quill.getLength()) {
    const found = [];
    for (let i = 0; i < length; i++) {
      if (this.quill.getText(i, 1) == symbol) {
        found.push(i);
      }
    }
    return found.length ? found[found.length - 1] : void 0;
  }
}

window.Editor = Editor;
export default Editor;
