import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Device } from 'components/Device';
import { ReactJWPlayer } from 'player/ReactJWPlayer';
import { ArtworkTitleWithAvatar } from 'components/artworks/ArtworkTitleWithAvatar';
import { CDN_ASSETS_URL } from 'constants/global';
import classNames from 'classnames';

import { requestArtworkWithUserBySlug } from 'features/artworks/api';

export default class Slide extends Component {
  static propTypes = {
    artworkId: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    deviceType: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    slideDirection: PropTypes.string,
  };

  static defaultProps = {
    slideDirection: null,
  };

  constructor() {
    super();

    this.state = {
      artwork: null,
    };
  }

  componentDidMount() {
    this.fetchArtwork();
  }

  fetchArtwork() {
    const { artworkId } = this.props;

    requestArtworkWithUserBySlug(artworkId).then((response) => {
      this.setState({
        artwork: response,
      });
    });
  }

  renderAuthor() {
    const { artwork } = this.state;

    if (!artwork) {
      return null;
    }

    return (
      <div className='device-slider__author'>
        <ArtworkTitleWithAvatar artwork={artwork} user={artwork.user} />
      </div>
    );
  }

  render() {
    const {
      children,
      deviceType,
      artworkId,
      isActive,
      slideDirection,
    } = this.props;

    return (
      <div className='d-flex justify-content-center'>
        <div className='device-slider__slide pos-rel d-flex flex-column'>
          <div
            className={classNames(
              `device-slider__item device-slider__item--${deviceType} align-self-center pos-rel`,
              {
                'device-slider__item--active': isActive,
                'device-slider__item--left':
                  isActive && slideDirection === 'left',
                'device-slider__item--right':
                  isActive && slideDirection === 'right',
              },
            )}
          >
            <Device type={deviceType}>
              <ReactJWPlayer
                url={`${CDN_ASSETS_URL}how-it-works/artworks/${artworkId}.mp4`}
              />
            </Device>
            {children}
          </div>
          {this.renderAuthor()}
        </div>
      </div>
    );
  }
}
