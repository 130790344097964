import { request } from 'utils/request';

import { mapResponseUserToUser } from 'features/users/api';
import * as Types from 'types';

import {
  ResponseArtwork,
  ResponseArtworkDetails,
  mapResponseArtworkToArtwork,
  mapResponseArtworkDetailsToArtworkDetails,
  ResponseArtworkWithUser,
} from './index';

export type Filter =
  | 'match'
  | 'available'
  | 'curated'
  | 'op'
  | 'ids'
  | 'artist_user'
  | 'user_wishlist'
  | 'purchased_by'
  | 'slate'
  | 'not_children';

export type Props = {
  filter?: Filter[] | Filter;
  match?: string;
  ids?: number[];
  order?: 'date' | 'popular' | 'alphabetical';
  direction?: 'asc' | 'desc';
  slate?: string;
  expand?: 'user';
  artistUser?: Types.User['id'];
  parentId?: number | null;
} & Types.PaginationProps;

export type QueryParams = {
  filter: Props['filter'];
  match: Props['match'];
  ids: Props['ids'];
  order?: Props['order'];
  direction?: Props['direction'];
  slate?: Props['slate'];
  artist_user?: Types.User['id'];
  parent_id?: number | null;
} & Types.PaginationParams;

const joinStrings: Record<string, ',' | '|'> = { state: '|' };
const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const propsToQueryString = <T, S>(props: T & Record<string, any>) => {
  return Object.keys(props).reduce<Partial<S>>((acc, key) => {
    const value = props[key];
    const newKey = camelToSnakeCase(key);
    return {
      ...acc,
      [newKey]: Array.isArray(value) ? value.join(joinStrings[key] || ',') : value,
    };
  }, {});
};

export const requestArtworks = async (
  props: Props,
  serializer?: 'legacy' | 'detail',
): Promise<Types.Artwork[]> => {
  const query = propsToQueryString<Props, QueryParams>(props);
  const { data } = await request.get<ResponseArtwork[]>(`/api/internal/artworks`, {
    params: {
      serializer,
      ...query,
    },
  });
  if (serializer === 'legacy') {
    return data as unknown as Types.Artwork[];
  } else if (serializer === 'detail') {
    return (data as ResponseArtworkDetails[]).map(mapResponseArtworkDetailsToArtworkDetails);
  } else {
    return data.map(mapResponseArtworkToArtwork);
  }
};

export const requestArtworksWithUser = (props: Props): Promise<Types.ArtworkWithUser[]> => {
  const query = propsToQueryString<Props, QueryParams>(props);

  return request
    .get<ResponseArtworkWithUser[]>('/api/internal/artworks', {
      params: {
        serializer: 'lite',
        expand: 'user',
        ...query,
      },
    })
    .then(({ data }) => {
      return data.map((artworkWithUserResponse) => ({
        ...mapResponseArtworkToArtwork(artworkWithUserResponse),
        user: mapResponseUserToUser(artworkWithUserResponse.user),
      }));
    });
};
