import React, { FC, useState } from 'react';
import Cookies from 'js-cookie';
import { AnimatePresence, motion } from 'framer-motion';

import { Modal } from 'components/modal';
import { Toggle } from 'components/UI/Toggle';

const isGDPR = window.__IS_GDPR_COUNTRY__;

const { pathname } = location;
const year = 60 * 60 * 24 * 365;

export const CookieBanner: FC = () => {
  const isUserAgreed =
    Cookies.get('agreement') === 'true' &&
    Cookies.get('essential-cookies') !== undefined &&
    Cookies.get('marketing-cookies') !== undefined;

  const isTermsPage = pathname === Routes.terms_pages_path();

  const [isModalOpen, setIsModalOpen] = useState(!isTermsPage && !isUserAgreed && isGDPR);

  const [showSetting, toggleSetting] = useState(false);

  const [marketingCookieSettings, setMarketingCookieSettings] = useState(true);

  const enableMarketingTracking = () => {
    if (marketingCookieSettings) {
      window.grantConsent();
    }
  };

  const storeCookieSettings = () => {
    Cookies.set('agreement', 'true', { expires: year });
    Cookies.set('essential-cookies', 'true', { expires: year });
    Cookies.set('marketing-cookies', marketingCookieSettings.toString(), {
      expires: year,
    });
  };

  const acceptButtonClickHandler = () => {
    setIsModalOpen(false);
    storeCookieSettings();
    enableMarketingTracking();
  };

  return (
    <Modal
      noMobileOverlay
      isOpen={isModalOpen}
      className='cookie-banner react-modal--bottom-modal'
      responsive
    >
      <div className='cookie-banner__title font-weight-bold mb-mini'>
        <span className='cookie-banner__cookie-icon' />
        <span>We’re using cookies</span>
      </div>
      <p className='cookie-banner__description'>
        By using Sedition, you agree to our use of cookies. We use cookies to offer a reliable and
        secure service, to provide relevant content and to analyse how our site is used. For more
        information please see our{' '}
        <a
          className='link-border'
          href={Routes.terms_pages_path()}
          target='_blank'
          rel='noreferrer noopener'
        >
          T&Cs
        </a>
      </p>
      <div className='d-flex justify-content-between'>
        <button
          tabIndex={0}
          onClick={() => toggleSetting(!showSetting)}
          className='btn btn--lg btn--neutral'
        >
          Customise
        </button>
        <button
          tabIndex={0}
          onClick={acceptButtonClickHandler}
          className='btn btn--lg btn--success'
        >
          Accept
        </button>
      </div>
      <AnimatePresence>
        {showSetting && (
          <motion.div
            initial={{ maxHeight: '0px', overflow: 'hidden', height: 'auto' }}
            animate={{ maxHeight: '300px' }}
            exit={{ maxHeight: '0px', overflow: 'hidden', height: 'auto' }}
            transition={{ ease: 'easeIn', duration: 0.2 }}
          >
            <div className='d-flex flex-column cookie-banner__options-container'>
              <div>
                <div
                  className='d-flex justify-content-between px-1 pb-mini'
                  style={{ paddingTop: 12 }}
                >
                  <span className='font-weight-bold align-self-center cookie-banner__option-title-text'>
                    Essential cookies
                  </span>
                  <span className='align-self-center cookie-banner__option-title-text'>
                    Always selected
                  </span>
                </div>
                <div className='px-1 pb-mini'>
                  <p className='cookie-banner__option-description-text'>
                    Necessary cookies help make a website usable by enabling basic functions like
                    page navigation and access to secure areas of the website. The website cannot
                    function properly without these cookies.
                  </p>
                </div>
              </div>
              <div>
                <div
                  className='d-flex justify-content-between px-1 pb-mini'
                  style={{ paddingTop: 12 }}
                >
                  <span
                    className='font-weight-bold align-self-center cookie-banner__option-title-text'
                    onClick={() => setMarketingCookieSettings(!marketingCookieSettings)}
                  >
                    Marketing cookies
                  </span>
                  <Toggle
                    id='marketing_cookies'
                    checked={marketingCookieSettings}
                    onChange={setMarketingCookieSettings}
                    label='Marketing cookies'
                  />
                </div>
                <div className='px-1 pb-mini'>
                  <p className='cookie-banner__option-description-text'>
                    Marketing cookies are used to track visitors across websites. The intention is
                    to display ads that are relevant and engaging for the individual user and
                    thereby more valuable for publishers and third party advertisers.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Modal>
  );
};
