import * as Types from 'types';

type AxiosExceptionError<T> = { response: T } | unknown;

/**
 * Various error helpers to have unified error handling API.
 */

/**
 * Guard. Checks if variable is Axios response with Renderable Error
 * @param exception any or RenderableErrorResponse
 * @returns
 */
export const isExceptionRenderableError = (
  exception: AxiosExceptionError<Types.AxiosRenderableErrorResponse<any>>,
): exception is { response: Types.AxiosRenderableErrorResponse<any> } => {
  return (exception as any)?.response?.data?.error;
};

/**
 * Main method to unify different error types.
 * Takes AxiosResponseRenderableError / RenderableError / new Error() / throw 'error
 * and returns { data: undefined, error: RenderableError }
 *
 * Provide bugsnagMessage to send this exception to bugsnag.
 *
 * @param exception
 * @param bugsnagMessage
 * @returns
 */
export const errorFromException = <T extends any>(
  exception: AxiosExceptionError<Types.AxiosRenderableErrorResponse<T>>,
  bugsnagMessage?: string,
): { data: undefined; error: Types.RenderableError<T> } => {
  if (isExceptionRenderableError(exception)) {
    return {
      data: undefined,
      error: {
        message: exception.response.data.error,
        errors: exception.response.data.errors,
        errorCode: exception.response.data.error_code,
        errorVariable: exception.response.data.error_variable,
        redirectTo: exception.response.data.redirect_to,
      },
    };
  } else {
    if (bugsnagMessage) {
      bugsnag.notify(bugsnagMessage, { metaData: { exception } });
    }
    const message = getErrorMessage(exception);
    return {
      data: undefined,
      error: {
        message: message,
        errors: [message],
        errorCode: undefined,
        errorVariable: undefined,
        redirectTo: undefined,
      },
    };
  }
};

export const errorFromString = (message: string): Types.RenderableError<string> => {
  return {
    message,
    errors: [message],
  };
};

export const getErrorMessage = (e: unknown) => {
  switch (typeof e) {
    case 'string':
      return e;
    case 'object':
      if ((e as any)?.message) {
        return ((e as any).message as string) || 'An unknown error has occurred.';
      }
    default:
      return 'An unknown error has occurred.';
  }
};
