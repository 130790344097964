import React, { FC } from 'react';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { Spinner } from 'components/Spinner';

import { Modal, Props as ModalProps } from './index';

const variants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

export type Props = {
  dangerLabel?: string;
  cancel?: boolean;
  cancelLabel?: string;
  confirm?: boolean;
  portalClassName?: string;
  isLoading?: boolean;
  onConfirm?: () => void;
} & Pick<ModalProps, 'isOpen' | 'title' | 'children' | 'onRequestClose' | 'dark'>;

export const ConfirmationModal: FC<Props> = ({
  confirm = true,
  cancel = false,
  cancelLabel = 'Cancel',
  dangerLabel = 'Confirm',
  isLoading,
  onConfirm,
  ...props
}) => {
  return (
    <Modal {...props}>
      <div className='standfirst standfirst--small'>{props.children}</div>
      <div className='mt-2 overflow-hidden' style={{ minHeight: 43 }}>
        <AnimatePresence exitBeforeEnter>
          {isLoading ? (
            <motion.div
              key='loader'
              variants={variants}
              initial='hidden'
              animate='visible'
              exit='hidden'
              className='d-flex align-items-center justify-content-center mh-inherit'
            >
              <Spinner light={props.dark} />
            </motion.div>
          ) : (
            <motion.form
              className='mb-0 text-center'
              onSubmit={(event) => {
                event.preventDefault();
                onConfirm?.();
              }}
              key='form'
              variants={variants}
              animate='visible'
              exit='hidden'
            >
              {cancel && (
                <button
                  role='button'
                  className={classNames('btn btn--border', { 'btn--border-light': props.dark })}
                  onClick={props.onRequestClose}
                >
                  {cancelLabel}
                </button>
              )}
              {confirm && (
                <button role='button' className='btn btn--border-cta' autoFocus>
                  {dangerLabel}
                </button>
              )}
            </motion.form>
          )}
        </AnimatePresence>
      </div>
    </Modal>
  );
};
