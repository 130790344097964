import { request } from 'utils/request';
import { errorFromException } from 'errors';

export { SubscribeBox } from './SubscribeBox';

export const variants = {
  content: {
    exit: {
      x: '-100%',
    },
    exitRight: {
      x: '100%',
    },
    visible: {
      x: 0,
    },
  },
  form: {
    exit: {
      y: 100,
    },
    visible: {
      y: 0,
    },
  },
};

export const subscribeByEmail = async (email: string) => {
  try {
    await request.post(Routes.subscribe_by_email_account_preferences_path(), { email });
    return { data: 'ok', error: undefined };
  } catch (e) {
    return errorFromException(e);
  }
};
