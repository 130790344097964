import React from 'react';

import { Menu } from 'navigation/components/Menu';

type Props = {
  userUrl: string;
  open: boolean;
};

export const UserAccountMenu: React.FC<Props> = ({ userUrl, open }) => {
  const tree = [
    { title: 'Profile', url: userUrl },
    { title: 'Account', url: Routes.account_root_path() },
    { title: 'Help Centre', url: Routes.learn_more_index_path() },
    { title: 'Cart', url: Routes.cart_path() },
    { title: 'Log Out', url: Routes.logout_path() },
  ];
  const css = { root: 'navigation-menu__items--right' };

  return <Menu tree={tree} css={css} open={open} />;
};
