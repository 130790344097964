import axios from 'axios';

import { getMetaTagValue } from 'utils/getMetaTagValue';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const isCSRFNeeded =
    config.method === 'put' ||
    config.method === 'delete' ||
    config.method === 'patch' ||
    config.method === 'post';

  if (isCSRFNeeded) {
    const csrfToken = getMetaTagValue('csrf-token');
    if (csrfToken && config.headers) {
      config.headers['X-CSRF-TOKEN'] = csrfToken;
    }
  }

  return config;
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
instance.defaults.headers.common['Accept'] = 'application/json, text/javascript';

export const request = instance;

// @ts-ignore
window.request = request;
