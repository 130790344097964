import { recaptcha } from 'utils/recaptcha';
import { request } from 'utils/request';

import { RECAPTCHA2_SITE_KEY, RECAPTCHA_MIN_REQUIRED_SCORE } from 'constants/global';
import { initializeAppleAuth, AppleAuthAPI } from 'services/appleAuth';
import * as Types from 'types';

const TOKEN_REQUEST_TIMOUT = 10000;

type Props = {
  el: HTMLElement;
  type: 'signup' | 'login';
  options?: {
    enableRecaptcha: boolean;
  };
};

type RecaptchaTokenResponse = { success: boolean; score: number };

export class AuthenticationForm {
  type: Props['type'];
  options: {
    enableRecaptcha: boolean;
  };
  $el: JQuery;
  $submitButton: JQuery;
  $signInWithAppleButton: JQuery;
  $recaptchaTokenInput: JQuery;
  $recaptchaSolutionTokenInput: JQuery;
  $recapchaPlaceholder: JQuery;
  tokenRequestTimeout?: number;
  score?: number;
  submitRequestRegistred?: boolean;
  signupImpossible = false;
  appleAuthAPI: Promise<AppleAuthAPI | null>;

  constructor(props: Props) {
    this.appleAuthAPI = initializeAppleAuth();
    this.type = props.type; // login | signup
    this.options = {
      enableRecaptcha: props.options ? props.options.enableRecaptcha : false,
    };
    this.$el = $(props.el); // form

    this.$submitButton = this.$el.find('.js-auth-form__submit');
    this.$signInWithAppleButton = this.$el.find('.js-auth-form__sign-in-with-apple');
    this.$recaptchaTokenInput = this.$el.find('.js-auth-form__recaptcha-token');
    this.$recaptchaSolutionTokenInput = this.$el.find('.js-auth-form__recaptcha-solution-token');
    this.$recapchaPlaceholder = this.$el.find('.js-auth-form__recaptcha-placeholder');

    this.onRecaptchaTokenAvailable = this.onRecaptchaTokenAvailable.bind(this);
    this.onRecaptchaScoreAvailable = this.onRecaptchaScoreAvailable.bind(this);
    this.onRecaptchaSolved = this.onRecaptchaSolved.bind(this);

    this.$el.on('submit', this.onFormSubmit.bind(this));
    if (this.options.enableRecaptcha) {
      recaptcha(this.type)
        .then(this.onRecaptchaTokenAvailable)
        .catch(() => {
          bugsnag.notify('Recaptcha failed to execute');
          this.setSignupImpossible();
        });
      this.tokenRequestTimeout = window.setTimeout(() => {
        bugsnag.notify(
          `Failed to retrieve recaptcha token in ${TOKEN_REQUEST_TIMOUT}ms. Signup impossible.`,
        );
        this.setSignupImpossible();
      }, TOKEN_REQUEST_TIMOUT);
    }

    this.$signInWithAppleButton.on('click', () => {
      if (this.type === 'signup') {
        const $terms = this.$el.find('.js-user-terms');
        const $termsContainer = $terms.closest('.control-group');
        $termsContainer.removeClass('animate-shake');
        if (!$terms.prop('checked')) {
          window.requestAnimationFrame(() => {
            $termsContainer.addClass('state-not-valid animate-shake');
          });

          return;
        }
        $termsContainer.removeClass('state-not-valid');
      }
      this.$signInWithAppleButton.prop({ disabled: true });

      this.appleAuthAPI.then((api) => {
        if (api) {
          api.signIn();
        } else {
          this.$signInWithAppleButton.prop({ disabled: false });
        }
      });
    });

    dataLayer.push({
      event: 'custom-event',
      category: 'forms',
      action: `${this.type}-form-initialized`,
    });
  }

  onRecaptchaTokenAvailable(token: unknown) {
    if (typeof token === 'string') {
      this.$recaptchaTokenInput.val(token);
      clearTimeout(this.tokenRequestTimeout);
      request
        .get<RecaptchaTokenResponse>(Routes.api_internal_recaptcha_token_path(token))
        .then((response) => response.data)
        .then(this.onRecaptchaScoreAvailable)
        .catch((response) => {
          window.bugsnag.notify({
            name: 'Unable to verify token on server side',
            token,
          });
        });
    } else {
      bugsnag.notify('Unknown value received from recaptcha');
    }
  }

  onRecaptchaScoreAvailable({ success, score, ...rest }: RecaptchaTokenResponse) {
    console.log('Score is', score);
    clearTimeout(this.tokenRequestTimeout);
    this.score = score;
    if (success && this.isSolutionRequired()) {
      this.renderRecaptcha();
    } else if (!success) {
      window.bugsnag.notify({
        name: 'Score could not be verified',
        arguments: rest,
      });
    }
    if (this.submitRequestRegistred) {
      this.$el.submit();
    }
  }

  onRecaptchaSolved(token: string) {
    this.$recaptchaSolutionTokenInput.val(token);
  }

  renderRecaptcha() {
    dataLayer.push({
      event: 'custom-event',
      category: 'forms',
      action: `${this.type}-recapcha-rendered`,
    });
    grecaptcha.render(this.$recapchaPlaceholder[0], {
      sitekey: RECAPTCHA2_SITE_KEY,
      theme: 'dark',
      callback: this.onRecaptchaSolved,
    });
  }

  setSignupImpossible() {
    if (!this.signupImpossible) {
      this.signupImpossible = true;
      this.$el
        .find('.js-auth-form__signup-impossible-group')
        .addClass('auth-form__signup-impossible-group--show');
      const image = new Image();
      image.addEventListener('error', () => {
        bugsnag.notify('Recapcha failed because google.com is unreachable');
      });
      image.src = `https://www.google.com/favicon.ico?${Date.now()}`;
    }
  }

  isSolutionRequired() {
    return this.score && this.score < RECAPTCHA_MIN_REQUIRED_SCORE;
  }

  isRecaptchaValid() {
    if (!this.options.enableRecaptcha) {
      return true;
    }

    if (!this.isSolutionRequired()) {
      return true;
    }

    const val = this.$recaptchaSolutionTokenInput.val();
    return val ? val.toString().length > 0 : false;
  }

  onFormSubmit() {
    if (this.options.enableRecaptcha && this.score === undefined) {
      // Submitted while score is still pending to be fetched.
      this.submitRequestRegistred = true;
      return false;
    }

    if (!this.isRecaptchaValid()) {
      dataLayer.push({
        event: 'custom-event',
        category: 'forms',
        action: `${this.type}-form-capcha-empty-error`,
      });
      this.$recapchaPlaceholder.addClass('animate-shake');
      setTimeout(() => {
        this.$recapchaPlaceholder.removeClass('animate-shake');
      }, 1000);
    }

    if (this.isRecaptchaValid() && this.$el.data('validation').isFormValid()) {
      this.$submitButton.prop({ disabled: true });
      return true;
    }
    return false;
  }

  setIntent(intent: Types.IntentKey | null, intentId?: string) {
    this.$el.find('input[name=sticky_intent]').val(intent || '');
    this.$el.find('input[name=sticky_intent_id]').val(intentId || '');
  }
}
