import classNames from 'classnames';
import { TransitionStatus } from 'react-transition-group/Transition';

export const theme = {
  gutter: 16,
};

export const breakpoints = {
  xs: 0,
  sm: 572,
  md: 768,
  std: 995,
  lg: 1171,
  xl: 1296,
};

export const fadeAnimationForState = (state: TransitionStatus) =>
  classNames('animated animated--quick', {
    'animate-fadeIn': state == 'entering',
    'animate-fadeOut': state == 'exiting',
  });
