/**
 * Beware caching ahead!
 *
 * When changing constants in YML file you need to
 * stop the server, stop webpack-dev-server,
 * touch this file,
 * touch the file that imports this file.
 */

// Recaptcha
export const RECAPTCHA3_SITE_KEY = '6Lc0LoAUAAAAAOrmsyYg2aOdXOa3G-PAQzL1fHRY';
export const RECAPTCHA2_SITE_KEY = '6LcQv4AUAAAAAKh2HIOZUB2bhxncVv4DEpVYq588';
export const RECAPTCHA_MIN_REQUIRED_SCORE = 0.5;

// Art Stream
export type StripePlan = {
  plan_id: string;
  price: number;
  trial_period_days: number;
};
export const MOCK_STRIPE = (process.env.MOCK_STRIPE?.toLowerCase?.() === 'true');
export const STRIPE_PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY as string;
export const ART_STREAM_MONTHLY = ({
  plan_id: process.env.ART_STREAM_MONTHLY_PLAN,
  price: 1500,
  trial_period_days: 7
} as StripePlan);
export const ART_STREAM_YEARLY = ({
    plan_id: process.env.ART_STREAM_YEARLY_PLAN,
    price: 12000,
    trial_period_days: 7,
} as StripePlan);

// Other
export const MAX_GIFT_MESSAGE_LENGTH = 300;
export const WIDTH_4K = 3840;

export const FEATURES = { sign_in_with_apple: false, support_v2: false };

export const CDN_ASSETS_URL = `${process.env.PROTOCOL}://${process.env.STATIC_ASSETS_HOST}/`;
export const assetUrl = (path = '') => `${CDN_ASSETS_URL}${path}`;

export const HOST = `${process.env.PROTOCOL}://${process.env.HOST}`;
export const APPLE_CLIENT_ID = 'com.seditionart';

// Ethereum
export const WALLET_VERIFY_MESSAGE = "To connect Wallet to your Sedition account you need first to verify the ownership of the wallet. Simply sign this message to prove your ownership. This will not cost you any Ether. One-time signature ID: %s";
export const CONTRACT_ADDRESS = process.env.ETH_CONTRACT_ADDRESS as string;
export const ISSUER_ADDRESS = process.env.ETH_ISSUER_ADDRESS as string;
export const CHAIN_ID = process.env.ETH_CHAIN_ID as string;
export const MINTING_FEE_WEI = process.env.ETH_MINTING_FEE_WEI as string;
export const ALCHEMY_API_KEY = process.env.ALCHEMY_API_KEY as string;
export const OPEN_SEA_URL = 'https://opensea.io/collection/sedition-art';

export const FACEBOOK_URL = 'https://fb.me/seditionart';
export const INSTAGRAM_URL = 'https://www.instagram.com/seditionart';
export const TWITTER_URL = 'https://twitter.com/seditionart';

export const FLUSH_CACHE_4 = TWITTER_URL;
