//
// Contains implementation of common UI elements.
//

import React from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import Modal from 'react-modal';

// @ts-ignore
import { GlobalTooltips } from 'utils/global-tooltips';
// @ts-ignore
import { GlobalTracking } from 'utils/global-tracking';
import { GlobalScrollTo } from 'utils/global-scrollto';
import { GlobalSearchBar } from 'utils/global-search-bar';
// @ts-ignore
import { PostForm } from 'posts/form';
// @ts-ignore
import { NavigationContainer } from 'navigation/NavigationContainer';
import { ModalVideoPlayer } from 'components/ModalVideoPlayer';
import { ZoomCarouselContainer } from 'misc/ZoomCarouselContainer';
import { countdown } from 'misc/countdown';
import { ArtworkTitleWithAvatar } from 'components/artworks/ArtworkTitleWithAvatar';
// @ts-ignore
import DeviceSlider from 'components/deviceSlider/DeviceSlider';
import { AuthenticationForm } from 'authentication/AuthenticationForm';
import { AuthenticationModal } from 'authentication/AuthenticationModal';
import { requestArtworkWithUserBySlug } from 'features/artworks/api';
import { CookieBanner } from 'banners/CookieBanner';
import { SubscribeBox } from 'components/SubscribeBox';

AuthenticationModal.bindModals();

// Expose form for use in other files.
// @ts-ignore
window.AuthenticationForm = AuthenticationForm;

GlobalTracking('.js-push-data-layer');

// Initialize Post Editor
$('.js-post-form').each(function () {
  new PostForm(this);
});

// Initialize Guest Account Navigation. Later to become main navigation.
$('.js-guest-account-navigation').each(function () {
  ReactDOM.render(<NavigationContainer />, this);
});

const cookieBannerContainer = $('.js-cookie-banner')?.first()?.get(0);

if (cookieBannerContainer) {
  Modal.setAppElement(cookieBannerContainer);
  ReactDOM.render(<CookieBanner />, cookieBannerContainer);
}

$('.js-popup-player').on('click', (event) => {
  event.preventDefault();
  const $el = $(event.currentTarget);
  const vimeoId = $el.data('vimeo-id');
  const youtubeId = $el.data('youtube-id');

  if (vimeoId || youtubeId) {
    const type = vimeoId ? 'vimeo' : 'youtube';
    const title = $el.data('title') || 'Video';
    const placeholder = $('<div />').appendTo($('body'));
    ReactDOM.render(
      <ModalVideoPlayer type={type} videoId={String(vimeoId || youtubeId)} title={title} />,
      placeholder[0],
    );
  }
});

$('.js-zoom-carousel').each((_index, el) => {
  // @ts-ignore
  ReactDOM.render(<ZoomCarouselContainer />, el);
});

$('.js-artwork-title-with-avatar').each((index, el) => {
  requestArtworkWithUserBySlug($(el).data('artwork-id')).then((artwork) => {
    ReactDOM.render(<ArtworkTitleWithAvatar artwork={artwork} user={artwork.user} />, el);
  });
});

$('.js-device-slider').each((_index, el) => {
  ReactDOM.render(<DeviceSlider />, el);
});

$('.js-submit-form').on('click', (event) => {
  event.preventDefault();
  const $el = $(event.currentTarget);
  $el.closest('form').trigger('submit');
});

$('.js-subscribe-box').each((_index, el) => {
  ReactDOM.render(<SubscribeBox />, el);
});

/**
 * Setup global tooltips.
 */
GlobalTooltips();

// Setup global search bar on all pages except /search
if (window.location.pathname !== Routes.search_path()) {
  GlobalSearchBar();
}

// API for legacy JS libs to issue Tooltip rendering.
document.addEventListener('globalTooltipsChange', GlobalTooltips);

$('.js-toggle-lang').on('click', function () {
  const $el = $(this);
  const params = queryString.parse(document.location.search);
  params['lang'] = $el.data('locale');
  document.location.href = document.location.pathname + '?' + queryString.stringify(params);
});

/**
 * Handle .js-scroll-to
 */
GlobalScrollTo();

/**
 * Handle .js-countdown
 */
$('.js-countdown').each(function () {
  countdown($(this));
});
