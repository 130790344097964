import React from 'react';

import { Menu } from 'navigation/components/Menu';

type Props = {
  open: boolean;
};

export const GuestAccountMenu: React.FC<Props> = ({ open }) => {
  const tree = [
    { title: 'Login', url: Routes.login_path() },
    { title: 'Create Account', url: Routes.signup_path() },
  ];
  const css = { root: 'navigation-menu__items--right' };

  return <Menu tree={tree} css={css} open={open} />;
};
