/**
 * Uses https://github.com/FezVrasta/popper.js
 *
 * Takes tooltip from title attribute. Uses dynamic position calculation,
 * which allows tooltips to excape overflow: hidden.
 */

import $ from 'jquery';
import Tooltip from 'tooltip.js';

// Only runs when title attribute is absent.
function tooltipContentFunction() {
  return this.dataset.title;
}

export const GlobalTooltips = (placement = 'top') => {
  $('.js-tooltip').each((_, el) => {
    const $el = $(el);
    const title = tooltipContentFunction.call(el);

    if ($el.data('tooltip')) {
      const instance = $el.data('tooltip');
      if (!title || title.length === 0) {
        instance.dispose();
      } else {
        instance.updateTitleContent(title);
      }
    } else if (title && title.length > 0) {
      const instance = new Tooltip(el, {
        container: document.body,
        placement: placement,
        title,
        template: `<div class="tooltip" role="tooltip" data-placement="${placement}">
          <div class="tooltip__arrow"></div>
          <div class="tooltip__inner"></div>
        </div>`,
      });
      $el.data('tooltip', instance);
    }
  });
};
