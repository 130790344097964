import * as Types from 'types';
import { _DeepNonNullableArray } from 'utility-types/dist/mapped-types';

import { request } from 'utils/request';

export { requestUsers } from './requestUsers';

export type ResponseUserCard = {
  id: number;
  name: string;
  avatar_url: string;
  url: string;
  editions_count?: number;
  followers_count: number;
  following_count: number;
  artist?: ArtistCardResponse;
};

export type ResponseUser = ResponseUserCard & {
  location?: string;
  biography: null | string;
  rank: number;
};

export type ResponseLoggedInUser = ResponseUser & {
  editions_count: number; // Redefine as required
  following_count: number; // Redefine as required
  followers_count: number; // Redefine as required
  access_token: string;
  invited_users_count: number;
  referral_credits_earned: number;
  invite_code_ios: string;
  art_stream_active: boolean;
  followed_users: number[];
  followed_playlists: number[];
};

type ArtistCardResponse = {
  artworks_count: number;
  collectors_count: number;
};

export const mapResponseUserCardToUserCard = (
  response: ResponseUserCard,
): Types.UserCard => ({
  id: response.id,
  name: response.name,
  avatarUrl: response.avatar_url,
  editionsCount: response.editions_count,
  followersCount: response.followers_count,
  followingCount: response.following_count,
  artist: response.artist
    ? {
        collectorsCount: response.artist.collectors_count,
        artworksCount: response.artist.artworks_count,
      }
    : undefined,
  url: response.url,
});

export const mapResponseUserToUser = (response: ResponseUser): Types.User => ({
  ...mapResponseUserCardToUserCard(response),
  location: response.location,
  biography: response.biography || undefined,
  rank: response.rank,
});

export const requestUser = (id: number) =>
  request
    .get<ResponseUserCard>(`/api/internal/users/${id}?expand=artist`)
    .then(({ data }) => {
      return mapResponseUserCardToUserCard(data);
    });
