import { request } from 'utils/request';

import * as Types from 'types';
import { ResponseUser, mapResponseUserToUser } from 'features/users/api';

export {
  requestArtworks,
  requestArtworksWithUser,
  Props as RequestArtworksProps,
} from './requestArtworks';

export { requestArtworksList } from './requestArtworksList';

export type ResponseArtwork = {
  id: number;
  slug: string;
  title: string;
  artist_name: string;
  vip: boolean;
  price: number;
  thumbnail_url: string;
  type: Types.ArtworkType;
  total_editions: number;
  artist_user_id: number;
  children_count: number;
  for_sale: boolean;
  sold_out: boolean;
  url: string;
  artist_url: string;
  dominant_color?: string;
  remaining_editions: number;
  unavailable_for_purchase?: boolean;
  auction_state?: Types.AuctionStates;
  nft?: boolean;
  not_giftable?: boolean;
};

export type ResponseArtworkWithUser = ResponseArtwork & { user: ResponseUser };

export type ResponseArtworkDetails = ResponseArtwork & {
  bundled_physical_objects: {
    id: number;
    title: string;
    price: number;
    available_quantity: number;
    images: {
      thumbnail: string;
      large: string;
    }[];
  }[];
  description: string;
  duration?: number;
  categories: string[];
  resolution?: {
    width: number;
    height: number;
  };
  published_at: number;
  auction?: {
    id: number;
    starts_at: number;
    ends_at?: number;
    winning_bid_id?: number;
  };
};

export const mapResponseArtworkToArtwork = (artwork: ResponseArtwork): Types.Artwork => ({
  id: artwork.id,
  slug: artwork.slug,
  title: artwork.title,
  thumbnailUrl: artwork.thumbnail_url,
  artistUserId: artwork.artist_user_id,
  artistName: artwork.artist_name,
  childrenCount: artwork.children_count,
  type: artwork.type,
  thumbnailType: 'artwork',
  dominantColor: artwork.dominant_color || '#000000',
  availableForPurchase: artwork.unavailable_for_purchase !== true,
  soldOut: artwork.sold_out,
  forSale: artwork.for_sale,
  price: artwork.price,
  totalEditions: artwork.total_editions,
  url: artwork.url,
  artistUrl: artwork.artist_url,
  remainingEditions: artwork.remaining_editions,
  auctionState: artwork.auction_state,
  nft: Boolean(artwork.nft),
  vip: artwork.vip,
  giftable: !Boolean(artwork.not_giftable),
});

export const mapResponseArtworkDetailsToArtworkDetails = (
  response: ResponseArtworkDetails,
): Types.ArtworkDetails => ({
  ...mapResponseArtworkToArtwork(response),
  children: [], // @TODO add to serializer
  bundles: [], // @TODO add to serializer
  description: response.description,
  bundledPhysicalObjects: response.bundled_physical_objects.map((physicalObject) => ({
    title: physicalObject.title,
    images: physicalObject.images,
  })),
  categories: response.categories,
  remainingEditions: response.remaining_editions,
  auction: response.auction
    ? {
        id: response.auction.id,
        startsAt: response.auction.starts_at * 1000,
        endsAt: response.auction.ends_at ? response.auction.ends_at * 1000 : undefined,
        winningBidId: response.auction.winning_bid_id,
      }
    : undefined,
  publishedAt: response.published_at ? new Date(response.published_at * 1000) : undefined,
  resolution: response.resolution,
  duration: response.duration,
});

export const mapLegacyArtworkToArtwork = (legacy: Types.LegacyArtwork): Types.Artwork => {
  return {
    id: legacy.id,
    slug: legacy.short_name,
    title: legacy.title,
    thumbnailUrl: legacy.thumbnail_max,
    artistUserId: legacy.artist_user_id,
    artistName: legacy.artist_name,
    childrenCount: legacy.children_count,
    thumbnailType: 'artwork',
    type: legacy.type,
    dominantColor: legacy.dominant_color,
    availableForPurchase: legacy.available_for_purchase,
    soldOut: legacy.sold_out === 1,
    forSale: legacy.for_sale === 1,
    url: legacy.url,
    artistUrl: legacy.artist_url,
    price: legacy.price,
    totalEditions: legacy.total_editions,
    remainingEditions: 0,
    nft: legacy.nft,
    vip: legacy.vip,
    giftable: !Boolean(legacy.not_giftable),
  };
};

export const requestArtwork = (id: number): Promise<Types.ArtworkDetails> =>
  request
    .get<ResponseArtworkDetails>(`/api/internal/artworks/${id}`)
    .then(({ data }) => mapResponseArtworkDetailsToArtworkDetails(data));

export const requestArtworkBySlug = (slug: string): Promise<Types.ArtworkDetails> =>
  request
    .get<ResponseArtworkDetails>(`/api/internal/artworks/${slug}`, {
      params: {
        id_type: 'short_name',
      },
    })
    .then(({ data }) => mapResponseArtworkDetailsToArtworkDetails(data));

export const requestArtworkWithUserBySlug = (slug: string): Promise<Types.ArtworkWithUser> =>
  request
    .get<ResponseArtworkWithUser>(`/api/internal/artworks/${slug}?id_type=short_name&expand=user`)
    .then(({ data }) => {
      return {
        ...mapResponseArtworkToArtwork(data),
        user: mapResponseUserToUser(data.user),
      };
    });

export const putArtworkCategories = async (
  artworkId: Types.Artwork['id'],
  tags: Types.Tag['name'][],
) => await request.put(`/api/internal/artworks/${artworkId}/categories`, { tags });
