import { HOST, APPLE_CLIENT_ID } from 'constants/global';
import { getMetaTagValue } from 'utils/getMetaTagValue';

class AppleAuth {
  _remoteScriptResolve: () => void = () => {};
  _remoteScriptLoaded = false;
  _remoteScriptPromise = new Promise<AppleAuth>((resolve, reject) => {
    this._remoteScriptResolve = () => {
      this._remoteScriptLoaded = true;
      resolve(this);
    };
  });

  loadRemoteScript() {
    if (this._remoteScriptLoaded) {
      return this._remoteScriptPromise;
    }
    const script = document.createElement('script');
    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.addEventListener('load', this._remoteScriptResolve);
    script.addEventListener('error', () => {
      bugsnag.notify('Unable to load Sign in with Apple script');
    });
    document.head.appendChild(script);
    return this._remoteScriptPromise;
  }
}

const appleAuth = new AppleAuth();

export type AppleAuthAPI = {
  signIn: () => void;
};

export const initializeAppleAuth = (): Promise<AppleAuthAPI | null> => {
  return appleAuth.loadRemoteScript().then(() => {
    if (AppleID) {
      AppleID.auth.init({
        clientId: APPLE_CLIENT_ID,
        scope: 'name email',
        redirectURI: `${HOST}/auth/apple/callback`,
        state: getMetaTagValue('csrf-token') || '',
        nonce: getMetaTagValue('apple-auth-nonce') || '',
      });
      return {
        signIn: AppleID.auth.signIn,
      };
    } else {
      bugsnag.notify('Unable to initialize AppleID');
      return null;
    }
  });
};
