import React, { Component } from 'react';
import Siema from 'siema';
import SliderNavigation from 'components/deviceSlider/SliderNavigation';
import SlideObject from 'components/deviceSlider/SlideObject';
import Slide from 'components/deviceSlider/Slide';
import slidesData from 'components/deviceSlider/slidesData';

export default class DeviceSlider extends Component {
  constructor() {
    super();

    this.state = {
      currentSlide: 0,
      direction: null,
    };

    this.handleSlideChange = this.handleSlideChange.bind(this);
    this.sliderInstance = React.createRef();
  }

  componentDidMount() {
    this.slider = new Siema({
      selector: this.sliderInstance.current,
      durationPerSlide: true,
      duration: 400,
      onChange: () => this.handleOnChange(),
    });

    this.handleContainerStyle();
  }

  static getClassListByType(sectionType) {
    return `device-slider-section device-slider-section--${sectionType}`;
  }

  // @TODO: move this side effect to Redux Thunk
  handleContainerStyle() {
    const { currentSlide } = this.state;
    const targetContainer = document.getElementsByClassName(
      'device-slider-section',
    )[0];
    targetContainer.className = DeviceSlider.getClassListByType(
      slidesData[currentSlide].background,
    );
  }

  handleOnChange() {
    this.setState({
      currentSlide: Number(this.slider.currentSlide),
      direction:
        this.state.currentSlide > this.slider.currentSlide ? 'right' : 'left',
    });
    this.handleContainerStyle();
  }

  handleSlideChange(slideId) {
    this.slider.goTo(slideId);
    this.setState(
      {
        currentSlide: slideId,
      },
      () => {
        this.handleContainerStyle();
      },
    );
  }

  render() {
    const { currentSlide, direction } = this.state;
    const slidesKeys = Object.keys(slidesData).map((key) => Number(key));

    return (
      <div>
        <div className='device-slider__wrapper' ref={this.sliderInstance}>
          {slidesData.map(({ artworkId, deviceType, objects }, index) => (
            <Slide
              key={artworkId}
              artworkId={artworkId}
              deviceType={deviceType}
              isActive={currentSlide === index}
              slideDirection={direction}
            >
              {objects.map((object) => (
                <SlideObject key={object.name} {...object} />
              ))}
            </Slide>
          ))}
        </div>
        <SliderNavigation
          slides={slidesKeys}
          currentSlide={currentSlide}
          onClick={this.handleSlideChange}
        />
      </div>
    );
  }
}
