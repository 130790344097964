import React, { FC, ReactNode } from 'react';

import { FACEBOOK_URL, INSTAGRAM_URL, TWITTER_URL } from 'constants/global';

import { Headline } from './Headline';

const Link: FC<{ children: ReactNode; href: string }> = ({ children, href }) => (
  <a
    href={href}
    className='btn btn--medium btn--border-light btn--border-hover-none'
    target='_blank'
  >
    {children}
  </a>
);

export const Success: FC = () => (
  <>
    <Headline slogan='Thank You.'>We've sent you a confirmation email</Headline>
    <div className='pos-rel'>
      <div className='subscribe-box__footer'>
        <div className='key-value__value sans mb-1'>You can also follow us on</div>
        <Link href={FACEBOOK_URL}>Facebook</Link>
        <Link href={INSTAGRAM_URL}>Instagram</Link>
        <Link href={TWITTER_URL}>Twitter</Link>
      </div>
    </div>
  </>
);
