import React, { useEffect, useState, FC, useCallback } from 'react';
import classNames from 'classnames';

import * as Types from 'types';

export type ArtworkThumbnailProps = {
  artwork: Types.Artwork;
  onClick?: () => void;
  url?: string;
} & Types.Styleable;

export const ArtworkThumbnail: React.FC<ArtworkThumbnailProps> = ({
  artwork,
  onClick,
  url,
  style,
  className,
  children,
}) => {
  const thumbnailUrl = artwork ? artwork.thumbnailUrl : undefined;
  const [imageState, setImageState] = useState<'loading' | 'loaded' | 'error'>('loading');

  const tagName = url ? 'a' : 'div';

  useEffect(() => {
    setImageState('loading');
    if (thumbnailUrl) {
      const image = new Image();
      image.onload = () => {
        setImageState('loaded');
      };
      image.onerror = () => {
        setImageState('error');
      };
      image.src = thumbnailUrl;
    }
  }, [thumbnailUrl]);

  return (
    <ArtworkThumbnailContainer
      tagName={tagName}
      href={url}
      className={classNames(className, {
        'artwork-component__thumbnail': true,
        'artwork-component__thumbnail--loaded': imageState === 'loaded',
        'artwork-component__thumbnail--error': imageState === 'error',
      })}
      style={{
        ...style,
        backgroundColor: artwork?.dominantColor ? artwork.dominantColor : style?.backgroundColor,
      }}
      onClickCapture={onClick}
    >
      {artwork ? (
        <img
          className='artwork-component__thumbnail-image'
          src={artwork.thumbnailUrl}
          alt={artwork.title}
        />
      ) : (
        <div className='aspect-image'>Image Element Loading</div>
      )}
      {children}
    </ArtworkThumbnailContainer>
  );
};

type ArtworkThumbnailContainerProps = {
  tagName?: keyof JSX.IntrinsicElements;
  href?: string;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const ArtworkThumbnailContainer: FC<ArtworkThumbnailContainerProps> = ({ tagName, ...props }) => {
  const Tag = tagName as keyof JSX.IntrinsicElements;
  return <Tag {...props} />;
};

ArtworkThumbnailContainer.defaultProps = {
  tagName: 'div',
};
