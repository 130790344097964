import React from 'react';
import classNames from 'classnames';
import truncate from 'truncate';
import { sprintf } from 'sprintf';

import * as Types from 'types';
import { Icon } from 'components/icons/Icon';
import { ConditionalAnchor } from 'components/ConditionalAnchor';
import { ComposingTooltip } from 'components/UI/ComposingTooltip';
import { formatArtworkPrice, formatNumber } from 'services/stringFormating';
import { WatermarkIndicator } from 'artworks/WatermarkIndicator';
import { NftIcon } from 'components/icons/NftIcon';

import { redDotTooltipText } from './index';
import { shouldOpenNewTab } from 'components/utils/helper';

export type Props = {
  artwork: Types.Artwork;
  url?: string;
  maxlength?: number;
  titleLinks?: boolean;
  showArtistName?: boolean;
  showWatermarkedIndicator?: boolean;
  small?: boolean;
  dark?: boolean;
  showEdition?: boolean;
  secondaryColumn?: boolean;
  showPrice?: boolean;
  enableNftIndicator?: boolean;
  isMinted?: boolean;
  onPlay?: (artworkId: number) => void;
  onClick?: () => void;
} & Types.Styleable;

export const ArtworkTitle: React.FC<Props> = ({
  showPrice = true,
  enableNftIndicator = true,
  isMinted,
  onPlay,
  ...props
}) => {
  const { artwork, maxlength, onClick, showWatermarkedIndicator } = props;
  let { showArtistName } = props;
  showArtistName = showArtistName === undefined ? true : showArtistName;

  const className = classNames(
    {
      'artwork-header': true,
      'artwork-header--sold-out': artwork.soldOut,
      'artwork-header--not-for-sale': !artwork.forSale,
      'artwork-header--with-secondary-column': onPlay || props.secondaryColumn,
      'artwork-header--sm': props.small,
      'artwork-header--dark': props.dark,
    },
    props.className,
  );

  let { title } = artwork;
  const isTruncated = maxlength && maxlength < artwork.title.length;
  if (maxlength) {
    title = truncate(title, maxlength);
  }
  const parentIsAnchor = Boolean(props.url || onClick);

  if (props.secondaryColumn && onPlay) {
    throw 'secondaryColumn and onPlay are not compatable with each other';
  }

  const artworkId = props.artwork.id;
  const handleOnPlay = React.useCallback(() => {
    onPlay && onPlay(artworkId);
  }, [artworkId]);
  const canShowPrice =
    showPrice && !artwork.soldOut && artwork.availableForPurchase && artwork.childrenCount === 0;

  const showNftIcon = enableNftIndicator && artwork.nft;

  const showSoldOutIcon =
    (artwork.soldOut || !artwork.availableForPurchase) && artwork.childrenCount === 0;

  // @ts-ignore
  const lArtistName = artwork.artistName || artwork.artist_name;

  return (
    <div className={className} style={props.style}>
      <ConditionalAnchor
        shouldWrap={parentIsAnchor}
        className='artwork-header__main'
        href={props.url || ''}
        onClick={
          onClick
            ? (event) => {
                event.preventDefault();
                onClick();
              }
            : undefined
        }
        shouldOpenNewTab={shouldOpenNewTab() ? true : false}
      >
        <div className='artwork-header__wrapper'>
          <h1 className='artwork-header__title' title={isTruncated ? artwork.title : undefined}>
            <ConditionalAnchor shouldWrap={!parentIsAnchor && props.titleLinks} href={artwork.url}>
              {showWatermarkedIndicator ? <WatermarkIndicator artwork={artwork} /> : undefined}
              {title}
            </ConditionalAnchor>
          </h1>
          {showArtistName && (
            <h2 className='artwork-header__name'>
              <ConditionalAnchor
                shouldWrap={!parentIsAnchor && props.titleLinks}
                href={artwork.artistUrl}
              >
                {lArtistName}
              </ConditionalAnchor>
            </h2>
          )}
          {props.showEdition && (
            <h3 className='artwork-header__edition'>
              {artwork.price && (
                <span className='artwork-header__price-text'>
                  {formatArtworkPrice(artwork.price)}
                  {artwork.totalEditions ? ' / ' : null}
                </span>
              )}
              {artwork.totalEditions &&
                sprintf('Digital Edition of %s', formatNumber(artwork.totalEditions.toString()))}
            </h3>
          )}
        </div>
      </ConditionalAnchor>
      {props.secondaryColumn ? (
        <div className='artwork-header__secondary'>
          {showSoldOutIcon && (
            <ComposingTooltip
              title={redDotTooltipText(artwork)}
              className='artwork-component__sold-out'
              style={{ marginBottom: '4px' }}
            />
          )}
          {canShowPrice ? (
            <div className='artwork-header__price'>{formatArtworkPrice(artwork.price)}</div>
          ) : null}
          {artwork.childrenCount > 0 ? (
            <ComposingTooltip
              title='Multiple Iterations'
              className='icon-layers vat d-inline-block fs-16'
            />
          ) : null}
          {showNftIcon && (
            <ComposingTooltip
              title={
                isMinted
                  ? 'Artwork on the blockchain'
                  : 'Artwork can be transferred to the blockchain'
              }
            >
              <NftIcon isMinted={isMinted} />
            </ComposingTooltip>
          )}
        </div>
      ) : null}
      {onPlay && (
        <div className='artwork-header__secondary' onClick={handleOnPlay}>
          <a href='#' className='player__btn player__btn--icon player__btn--icon-small no-hover'>
            <Icon icon='play' />
          </a>
        </div>
      )}
    </div>
  );
};
