import animateScrollTo from 'animated-scroll-to';

export const GlobalScrollTo = () => {
  $('.js-scroll-to').each((_, el) => {
    $(el).on('click', () => {
      const destinationSelector = el.dataset['scrollTo']
        ? el.dataset['scrollTo']
        : el.getAttribute('href');

      if (destinationSelector) {
        const destinationElement = $(destinationSelector)[0];
        if (destinationElement) {
          let offset: number = parseInt(el.dataset['scrollOffset'] || '0', 10);

          offset = Number.isNaN(offset) ? 0 : offset;
          animateScrollTo(destinationElement.getBoundingClientRect().top + window.scrollY, {
            verticalOffset: -offset,
          });
        }
      }
    });
  });
};
