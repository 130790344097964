import React from 'react';

import { Menu } from 'navigation/components/Menu';

type Props = {
  open: boolean;
};

export const UserMainMenu: React.FC<Props> = ({ open }) => {
  const tree = [
    { title: 'Artworks', url: Routes.artworks_path() },
    { title: 'Artists', url: Routes.artists_path() },
    { title: 'Vault', url: Routes.vault_path() },
    { title: 'Art Stream', url: Routes.art_stream_path() },
    { title: 'Art for Business', url: Routes.public_displays_pages_path() },
    { title: 'Muse Frame', url: Routes.muse_frame_pages_path() },
    { title: 'News', url: Routes.posts_path() },
  ];

  return <Menu tree={tree} open={open} />;
};
