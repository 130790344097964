export { ArtworkTitle } from './ArtworkTitle';

import * as Types from 'types';

export const redDotTooltipText = (artwork: Types.Artwork) => {
  if (artwork.soldOut) {
    return 'Sold out';
  } else {
    switch (artwork.auctionState) {
      case Types.AuctionStates.ongoing:
        return 'Live auction';
      case Types.AuctionStates.upcoming:
        return 'Auction upcoming';
      default:
        return 'Not for sale';
    }
  }
};
