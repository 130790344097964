import Quill from 'quill';
const EmbedBlot = Quill.import('blots/embed');

class MentionBlot extends EmbedBlot {
  static blotName = 'mention';
  static className = 'mention';
  static tagName = 'span';

  static template(data) {
    return `<a href='${data.url}'><img src='${data.avatar_url}' /><span class='mention__name'>${data.name}</span></a>`;
  }

  static create(id) {
    const $node = $(super.create());
    $.get(`/api/internal/users/${id}.json`, (response) => {
      $node.html(this.template(response));
    });
    $node
      .attr('data-mentionable-id', id)
      .attr('data-mentionable-type', 'user')
      .attr('contenteditable', 'false').html('loading...');
    return $node.get(0);
  }

  static value(domNode) {
    return domNode.dataset.mentionableId;
  }

  static getInstances(editor) {
    return editor.getContents().reduce((ids, { insert }) => {
      if (insert.mention && parseInt(insert.mention) != NaN) {
        ids.push(parseInt(insert.mention));
      }
      return ids;
    }, []);
  }
}

Quill.register(MentionBlot);

export default MentionBlot;

