import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SliderNavigation = ({ currentSlide, onClick, slides }) => (
  <div className='inline-paging inline-paging--rounded device-slider__nav d-flex justify-content-center pos-rel'>
    {slides.map((slideId) => (
      <a
        key={slideId}
        onClick={() => onClick(slideId)}
        className={classNames({
          active: currentSlide === slideId
        })}
      />
    ))}
  </div>
);

SliderNavigation.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  slides: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default SliderNavigation;
