import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { Spinner } from 'components/Spinner';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useKeyPress } from 'hooks/useKeyPress';

type Props = {
  className?: string;
  showLoading?: boolean;
  onRequestClose?: () => void;
  onSubmit?: (query: string) => void;
};

export const SearchBar: React.FC<Props> = ({
  className,
  showLoading,
  onRequestClose = () => {},
  onSubmit,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [queryTooShort, setQueryTooShort] = useState(false);
  const [focused, setFocused] = useState(false);
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isESCPressed = useKeyPress(['Escape']);

  useOutsideClick(containerRef, onRequestClose);

  const searchSubmit = (event: React.FormEvent) => {
    if (searchQuery.length < 2) {
      setQueryTooShort(true);
      event.preventDefault();
      inputRef.current?.focus();
    } else if (onSubmit) {
      // Delegate to parent if callback is specified
      onSubmit(searchQuery);
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isESCPressed) {
      onRequestClose();
    }
  }, [isESCPressed, onRequestClose]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setQueryTooShort(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [queryTooShort]);

  return (
    <div
      ref={(ref) => setContainerRef(ref)}
      className={classnames('widget-search-toolbar', className)}
    >
      <div className='container py-2'>
        <form
          action={Routes.search_path()}
          className={classnames('copy-text-component copy-text-component--small mx-auto mb-0', {
            'copy-text-component--focused': focused,
          })}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onSubmit={(event) => searchSubmit(event)}
        >
          <div className='copy-text-component__input'>
            <input
              className={classnames(
                'input-blended flex-grow widget-search-toolbar__input pos-rel',
                {
                  'animate-shake': queryTooShort,
                },
              )}
              type='text'
              name='query'
              placeholder='Search artists or artworks'
              ref={inputRef}
              onChange={(e) => setSearchQuery(e.currentTarget.value)}
              value={searchQuery}
            />
            <button
              type='submit'
              className='widget-search-toolbar__icon icon-search'
              onSubmit={(event) => searchSubmit(event)}
            />
            {showLoading ? <Spinner className='mr-mini' style={{ marginBottom: 4 }} light /> : null}
            {!showLoading && searchQuery.length > 0 ? (
              <button
                type='button'
                className='btn--blended pos-abs h-100 d-md-none'
                style={{ right: 0, paddingLeft: '12px', paddingRight: '12px' }}
                onClick={() => {
                  setSearchQuery('');
                  inputRef.current?.focus();
                }}
              >
                <span
                  className='icon icon-default-close pos-rel'
                  style={{ color: '#fff', fontSize: '16px', top: '1px' }}
                />
              </button>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};
