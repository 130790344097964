import React from 'react';
import posed from 'react-pose';
import { shuffle } from 'lodash';
import withSizes from 'react-sizes';

import { requestArtworksWithUser } from 'features/artworks/api';
import * as Types from 'types';
import { ArtworkThumbnail } from 'components/artworks/ArtworkThumbnail';
import { ArtworkTitleWithAvatar } from 'components/artworks/ArtworkTitleWithAvatar';
import { BREAKPOINTS } from 'constants/global';

type ItemPosesKeys =
  | 'inactive'
  | 'inactive-sm'
  | 'inactive-lg'
  | 'active'
  | 'active-sm'
  | 'active-lg';

type ItemPoseProps = {
  minWidth: string;
  maxWidth: string;
  marginLeft: string;
};

const CAROUSEL_ITEM_POSES: Record<ItemPosesKeys, ItemPoseProps> = {
  inactive: {
    minWidth: '80%',
    maxWidth: '80%',
    marginLeft: '10%',
  },
  'inactive-sm': {
    minWidth: '25%',
    maxWidth: '25%',
    marginLeft: '2%',
  },
  'inactive-lg': {
    minWidth: '15%',
    maxWidth: '15%',
    marginLeft: '2%',
  },
  active: {
    minWidth: '80%',
    maxWidth: '80%',
    marginLeft: '10%',
  },
  'active-sm': {
    minWidth: '42%',
    maxWidth: '42%',
    marginLeft: '2%',
  },
  'active-lg': {
    minWidth: '28%',
    maxWidth: '28%',
    marginLeft: '2%',
  },
};

const TITLE_ACTIVE_POSE = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 1000,
    ease: 'anticipate',
  },
};

const TITLE_INACTIVE_POSE = {
  opacity: 0,
  y: -50,
};

const CAROUSEL_TITLE_POSES: Record<ItemPosesKeys, any> = {
  'inactive-sm': TITLE_INACTIVE_POSE,
  'inactive-lg': TITLE_INACTIVE_POSE,
  inactive: TITLE_INACTIVE_POSE,
  active: TITLE_ACTIVE_POSE,
  'active-sm': TITLE_ACTIVE_POSE,
  'active-lg': TITLE_ACTIVE_POSE,
};

const TITLE_HEIGHT = 100;

const CarouselItem = posed.div(CAROUSEL_ITEM_POSES);
const CarouselItemTitle = posed.div(CAROUSEL_TITLE_POSES);

type HOCProps = {
  isWindowWidthLg: boolean;
  isWindowWidthSm: boolean;
  width: number;
  windowWidth: number;
};

type Props = {} & HOCProps;

type State = {
  activeId: null | number;
  artworks: Types.ArtworkWithUser[];
};

class ZoomCarousel extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeId: null,
      artworks: [],
    };
  }

  componentDidMount() {
    requestArtworksWithUser({
      filter: 'curated',
      expand: 'user',
    }).then((artworksWithUser) => {
      const shuffled = shuffle(artworksWithUser.slice(0, 12)).slice(0, 5);
      this.setState({
        artworks: shuffled,
        activeId: shuffled[2].id,
      });
    });
  }

  activate(id: number) {
    this.setState({ activeId: id });
  }

  getWindowSizeSuffix(state: 'active' | 'inactive'): ItemPosesKeys {
    if (this.props.isWindowWidthLg) {
      return state === 'active' ? 'active-lg' : 'inactive-lg';
    } else if (this.props.isWindowWidthSm) {
      return state === 'active' ? 'active-sm' : 'inactive-sm';
    }
    return state;
  }

  getPoseForItem(artworkId: number): ItemPosesKeys {
    const isActive = artworkId == this.state.activeId;
    const state = isActive ? 'active' : 'inactive';
    return this.getWindowSizeSuffix(state);
  }

  calculateHeight() {
    const { minWidth } =
      CAROUSEL_ITEM_POSES[this.getWindowSizeSuffix('active')];
    const minWidthPercent = parseInt(minWidth, 10) / 100;
    const minWidthPx = this.props.width * minWidthPercent;
    const thumbnailHeight = (minWidthPx / 16) * 9;
    const TITLE_TOP_MARGIN = 32;
    const CAROUSEL_TOP_PADDING = 16;

    return (
      thumbnailHeight + TITLE_TOP_MARGIN + TITLE_HEIGHT + CAROUSEL_TOP_PADDING
    );
  }

  render() {
    if (this.state.artworks.length === 0) {
      return null;
    }

    return (
      <div
        className='d-flex align-items-center pt-1 w-100 pos-rel'
        style={{
          color: '#111116',
          overflow: 'hidden',
          minHeight: this.calculateHeight(),
        }}
      >
        {this.state.artworks
          .slice(0, Object.keys(CAROUSEL_ITEM_POSES).length)
          .map((artwork) => {
            const isActive = artwork.id === this.state.activeId;
            return (
              <CarouselItem
                pose={this.getPoseForItem(artwork.id)}
                key={artwork.id}
                onClick={() => {
                  this.activate(artwork.id);
                }}
              >
                <ArtworkThumbnail
                  artwork={artwork}
                  style={{
                    zIndex: 2,
                    boxShadow: isActive
                      ? '0 0 15px rgba(0, 0, 0, 0.15)'
                      : 'initial',
                  }}
                />
                <CarouselItemTitle
                  className='mt-2 w-100'
                  style={{
                    height: TITLE_HEIGHT,
                    // position: 'absolute'
                  }}
                >
                  <ArtworkTitleWithAvatar
                    artwork={artwork}
                    user={artwork.user}
                    className='d-flex w-100 align-items-center'
                  />
                </CarouselItemTitle>
              </CarouselItem>
            );
          })}
      </div>
    );
  }
}

export const ZoomCarouselContainer = withSizes<{}, Props>(({ width }) => ({
  windowWidth: width,
  isWindowWidthLg: width >= BREAKPOINTS.lg,
  isWindowWidthSm: width >= BREAKPOINTS.sm,
  width,
}))(ZoomCarousel);
