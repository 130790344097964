import React from 'react';

type Props = {
  href?: string;
  onClick?: (event: React.BaseSyntheticEvent) => void;
  className?: string;
  shouldWrap?: boolean;
  shouldOpenNewTab?: boolean;
  children: React.ReactNode;
};

/**
 * Wraps children content in <a /> tag based on condition
 */
export const ConditionalAnchor: React.FunctionComponent<Props> = ({
  children,
  className,
  href,
  onClick,
  shouldWrap,
  shouldOpenNewTab = false,
}: Props) => {
  if (shouldWrap) {
    return (
      <a
        className={className}
        href={href}
        onClick={onClick}
        target={shouldOpenNewTab ? '_blank' : undefined}
      >
        {children}
      </a>
    );
  } else {
    return (
      <span className={className} onClick={onClick}>
        {children}
      </span>
    );
  }
};
