import React, { FC, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import classnames from 'classnames';

import { Spinner } from 'components/Spinner';
import { emailValidator } from 'utils/validators';

import { Headline } from './Headline';
import { Success } from './Success';
import { variants, subscribeByEmail } from './index';

type UIStates = 'default' | 'loading' | 'error' | 'success';

export const SubscribeBox: FC = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [uiState, setUiState] = useState<UIStates>('default');
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState<undefined | boolean>();

  const subscribe = async () => {
    setEmailValid(true);

    if (!emailValidator(email)) {
      setEmailValid(false);
      setTimeout(() => {
        setEmailValid(undefined);
      }, 500);
      return;
    }

    setUiState('loading');

    const { data, error } = await subscribeByEmail(email);
    if (error) {
      setUiState('error');
      setError(error.message);
    } else {
      setUiState('success');
    }
  };

  return (
    <div className='subscribe-box'>
      <div className='subscribe-box__background' />
      <div className='w-100'>
        <AnimatePresence exitBeforeEnter>
          {uiState === 'success' ? (
            <motion.div
              key='success'
              variants={variants.content}
              initial='exitRight'
              animate='visible'
              exit='exit'
            >
              <Success />
            </motion.div>
          ) : (
            <motion.div key='email' variants={variants.content} animate='visible' exit='exit'>
              <Headline slogan='Stay out of the grey zone.'>
                Join our newsletter. <span className='nowrap'>Discover art.</span>
              </Headline>
              <AnimatePresence exitBeforeEnter initial={false}>
                {(uiState === 'default' || uiState === 'error') && (
                  <motion.form
                    key='form'
                    className='mb-0 pos-rel'
                    onSubmit={(event) => {
                      event.preventDefault();
                      subscribe();
                    }}
                    style={{ minHeight: 38 }}
                    variants={variants.form}
                    initial='exit'
                    animate='visible'
                    exit='exit'
                    noValidate
                  >
                    <div>
                      <input
                        className={classnames('subscribe-box__text-input', {
                          'animate-shake': emailValid === false,
                        })}
                        type='email'
                        autoCapitalize='off'
                        autoCorrect='off'
                        placeholder='Enter email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                      <div className='subscribe-box__submit-container vat'>
                        <button className='btn btn--input btn--border-light btn--border-subscribe'>
                          Subscribe
                        </button>
                      </div>
                    </div>
                    {error && error.length > 0 && (
                      <div className='sans mt-1 text-error'>{error}</div>
                    )}
                  </motion.form>
                )}
                {uiState === 'loading' && (
                  <motion.div
                    key='loading'
                    className='d-flex align-items-center justify-content-center'
                    style={{ minHeight: 38 }}
                    variants={variants.form}
                    initial='exit'
                    animate='visible'
                    exit='exit'
                  >
                    <Spinner light />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
