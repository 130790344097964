import React, { CSSProperties } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import * as Types from 'types';

export type Props = {
  isOpen?: boolean;
  dark?: boolean;
  large?: boolean;
  variant?: 'default' | 'squeezed' | 'artwork-preview' | 'wide' | 'extra-wide';
  children: React.ReactNode;
  overlayClassName?: string;
  portalClassName?: string;
  style?: CSSProperties;
  styleContent?: CSSProperties;
  styleContentWrapper?: CSSProperties;
  title?: string;
  transparentMobileOverlay?: boolean;
  noMobileOverlay?: boolean;
  /**
   * Take whole screen on smaller devices.
   */
  responsive?: boolean;
  closeDisabled?: string;
  onRequestClose?: () => void;
  onAfterOpen?: () => void;
} & Types.Styleable;

export const Modal = ({
  variant = 'default',
  isOpen = false,
  transparentMobileOverlay = false,
  noMobileOverlay = false,
  ...props
}: Props) => {
  const className = classNames(
    `react-modal react-modal--${variant}`,
    {
      'react-modal--light': !props.dark,
      'react-modal--lg': props.large,
      'react-modal--responsive': props.responsive,
    },
    props.className,
  );

  return (
    <ReactModal
      className={{
        base: className,
        afterOpen: 'react-modal--open',
        beforeClose: 'react-modal--before-close',
      }}
      overlayClassName={{
        base: classNames(
          'react-modal-overlay',
          {
            'transparent-on-mobile': transparentMobileOverlay,
            'no-mobile-overlay': noMobileOverlay,
          },
          props.overlayClassName,
        ),
        afterOpen: 'react-modal-overlay--open',
        beforeClose: 'react-modal-overlay--before-close',
      }}
      isOpen={isOpen}
      portalClassName={classNames('react-modal-portal', props.portalClassName)}
      onRequestClose={props.onRequestClose}
      onAfterOpen={props.onAfterOpen}
      style={{
        content: props.style,
      }}
    >
      <div className='react-modal__contents-wrapper' style={props.styleContentWrapper}>
        {props.title && <div className='modal__title mb-1'>{props.title}</div>}
        <div className='react-modal__content' style={props.styleContent}>
          {props.children}
        </div>
        {props.onRequestClose && (
          <button
            className='modal-close'
            role='button'
            tabIndex={0}
            onKeyDown={props.onRequestClose}
            onClick={props.onRequestClose}
            disabled={props.closeDisabled !== undefined}
            title={props.closeDisabled}
          />
        )}
      </div>
    </ReactModal>
  );
};

export { ConfirmationModal } from './ConfirmationModal';
export { ErrorModal } from './ErrorModal';
