import React from 'react';
import classNames from 'classnames';

const DEFAULT_CSS = {
  root: 'navigation-menu__items',
  item: 'navigation-menu__item',
  itemActive: 'navigation-menu__item--active',
  link: 'navigation-menu__link',
  linkLang: 'navigation-menu__link navigation-menu__link--lang',
  linkItemLang: 'navigation-menu__lang',
};

type MenuItem = {
  url: string;
  title: string;
  className?: string;
};

type Props = {
  open: boolean;
  tree: MenuItem[];
  css?: {
    root?: string;
    item?: string;
    itemActive?: string;
    link?: string;
  };
};

export const Menu: React.FC<Props> = (props) => {
  const css = {
    root: 'navigation-menu__items--left',
    item: undefined,
    itemActive: undefined,
    link: undefined,
    ...props.css,
  };
  css.root = classNames(DEFAULT_CSS.root, css.root);
  css.item = classNames(DEFAULT_CSS.item, css.item);
  css.itemActive = classNames(DEFAULT_CSS.itemActive, css.itemActive);
  css.link = classNames(DEFAULT_CSS.link, css.link);

  return (
    <ul className={`${css.root} ${props.open ? 'navigation-menu__items--open' : ''}`}>
      {props.tree.map((item, index) => {
        let itemClassNames = css.item;

        const expression = new RegExp(`^${item.url}`);
        if (window.location.pathname.match(expression)) {
          itemClassNames = classNames(itemClassNames, css.itemActive);
        }

        return (
          <li className={itemClassNames} key={index}>
            <a className={classNames(css.link, item.className)} href={item.url}>
              {item.title}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
