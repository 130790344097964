import React, { FC, useState } from 'react';

import { Modal } from 'components/modal';

export const PopupPlayer: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Modal variant='extra-wide' isOpen={isOpen} onRequestClose={() => setIsOpen(false)} dark>
      <div className='keep-aspect-ratio' style={{ overflow: 'hidden' }}>
        <div className='keep-aspect-ratio__content'>{children}</div>
      </div>
    </Modal>
  );
};
