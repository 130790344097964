import { AuthenticationForm } from 'authentication/AuthenticationForm';
import { showModalCustomEvent } from 'utils/tracking/showModalCustomEvent';
import { hideModalCustomEvent } from 'utils/tracking/hideModalCustomEvent';
import * as Types from 'types';

export type ShowAuthenticationModalProps = {
  intent?: Types.IntentKey;
  intentId?: string;
};

export class AuthenticationModal {
  type: 'signup' | 'login';
  $el: JQuery;
  form?: AuthenticationForm;

  constructor(el: HTMLElement, type: 'signup' | 'login') {
    this.type = type;
    this.$el = $(el);
    this.onTriggerClick = this.onTriggerClick.bind(this);
    this.onHide = this.onHide.bind(this);
    this.show = this.show.bind(this);
    this.switchModal = this.switchModal.bind(this);
    this.handleDocumentShowEvent = this.handleDocumentShowEvent.bind(this);

    if (window.location.hash === `#${this.type}-form`) {
      this.show();
    }
    $(document)
      // @ts-ignore
      .on('click', `.js-show-${this.type}-modal`, this.onTriggerClick)
      .on(`show-${type}-auth-modal`, this.handleDocumentShowEvent);
    this.$el.on('hide', this.onHide);
    // @ts-ignore
    this.$el.on('click', '.js-switch-modal', this.switchModal);
  }

  handleDocumentShowEvent(event: any, params?: ShowAuthenticationModalProps) {
    this.show(params);
  }

  show(options: ShowAuthenticationModalProps = {}) {
    history.replaceState(undefined, '', this.hash());
    // @ts-ignore method exists since this is extended jQuery
    this.$el.modal('show');
    if (!this.form) {
      this.form = new AuthenticationForm({
        el: this.$el.find('.js-auth-form')[0],
        type: this.type,
        options: { enableRecaptcha: this.type === 'signup' },
      });
    }
    if (options.intent) {
      this.form.setIntent(options.intent, options.intentId);
    } else {
      this.form.setIntent(null);
    }
    dataLayer.push(showModalCustomEvent(this.type));
  }

  hash() {
    return `#${this.type}-form`;
  }

  onTriggerClick(event: React.BaseSyntheticEvent) {
    event.preventDefault();
    this.show();
  }

  onHide() {
    history.replaceState(undefined, '', ' ');
    dataLayer.push(hideModalCustomEvent(this.type));
  }

  switchModal(event: React.BaseSyntheticEvent) {
    event.preventDefault();
    // @ts-ignore
    this.$el.modal('hide');
    const $target = $(event.currentTarget);
    if ($target && $target.length > 0) {
      const matches = ($target.attr('href') || '').match(/#(.+)\-modal/);
      const newModalType = matches ? matches[1] : undefined;
      if (newModalType) {
        $(document).trigger(`show-${newModalType}-auth-modal`);
      }
    }
  }

  static bindModals() {
    $('.js-signup-modal').each(function () {
      new AuthenticationModal(this, 'signup');
    });
    $('.js-login-modal').each(function () {
      new AuthenticationModal(this, 'login');
    });
  }
}
