import React from 'react';
import PropTypes from 'prop-types';

import { Menu } from 'navigation/components/Menu';

type Props = {
  open: boolean;
};

export const GuestMainMenu: React.FC<Props> = ({ open }) => {
  const tree = [
    { title: 'Artworks', url: Routes.artworks_path() },
    { title: 'Artists', url: Routes.artists_path() },
    { title: 'Art Stream', url: Routes.art_stream_path() },
    { title: 'Art for Business', url: Routes.public_displays_pages_path() },
    { title: 'Muse Frame', url: Routes.muse_frame_pages_path() },
    { title: 'How it Works', url: Routes.how_it_works_pages_path() },
  ];

  return <Menu tree={tree} open={open} />;
};

GuestMainMenu.propTypes = {
  open: PropTypes.bool.isRequired,
};
