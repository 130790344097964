import React, { CSSProperties } from 'react';

import { ComposingTooltip } from 'components/UI/ComposingTooltip';

export type Props = {
  isMinted?: boolean;
  label?: string;
  defaultColor?: string;
  className?: string;
  style?: CSSProperties;
};

export const NftIcon: React.FC<Props> = ({
  isMinted = false,
  label = 'Mint',
  defaultColor = 'inherit',
  style = { lineHeight: 1 },
  className,
}) => {
  const color = isMinted ? '#f8423a' : defaultColor;

  return (
    <div className={`${className ? className : ''} artwork-header__nft-container`}>
      <i
        className='icon-nft pos-abs'
        style={{
          fontSize: '11px',
          lineHeight: 1,
          color,
          ...style,
        }}
      />
      <span style={{ ...style, color, paddingLeft: 11, fontSize: 14, textTransform: 'none' }}>
        {label}
      </span>
    </div>
  );
};

export const NftIconWithTooltip: React.FC<Props> = (props) => {
  return (
    <ComposingTooltip
      title={
        props.isMinted
          ? 'Artwork on the blockchain'
          : 'Artwork can be transferred to the blockchain'
      }
    >
      <NftIcon {...props} />
    </ComposingTooltip>
  );
};
