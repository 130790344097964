import React, { useCallback, useState, useEffect, createRef, useRef } from 'react';

import { GuestAccountMenu } from 'navigation/components/GuestAccountMenu';
import { UserAccountMenu } from 'navigation/components/UserAccountMenu';
import { UserMainMenu } from 'navigation/components/UserMainMenu';
import { GuestMainMenu } from 'navigation/components/GuestMainMenu';

import { toggleIcons } from 'utils/helpers';
import { CDN_ASSETS_URL } from 'constants/global';
import { breakpoints } from 'services/theme';

type Props = {};

export const NavigationContainer: React.FC<Props> = () => {
  const ref = createRef<HTMLDivElement>();
  const [isMainMenuOpen, setMainMenuOpen] = useState(false);
  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false);
  const searchRef = useRef(null);

  const toggleMainMenu = useCallback(() => {
    if (isMainMenuOpen) {
      setMainMenuOpen(false);
      toggleIcons('burger-icon', 'burger-close-icon'); // show burger icon
    } else {
      setMainMenuOpen(true);
      toggleIcons('burger-close-icon', 'burger-icon'); // show close icon
    }
    setAccountMenuOpen(false);
  }, [isMainMenuOpen]);

  const toggleAccountMenu = useCallback(() => {
    isAccountMenuOpen ? setAccountMenuOpen(false) : setAccountMenuOpen(true);

    setMainMenuOpen(false);
  }, [isAccountMenuOpen]);

  // on mobile & tablet: close both menus if search is open
  const closeBothMenus = () => {
    setMainMenuOpen(false);
    setAccountMenuOpen(false);
    toggleIcons('burger-icon', 'burger-close-icon'); // show burger icon
  };

  const handleMutation = (mutationsList: MutationRecord[]) => {
    const myClass = 'navigation-menu__icon--show';

    mutationsList.forEach((mutation) => {
      const { attributeName, target } = mutation;
      if (attributeName === 'class' && (target as Element).classList.contains(myClass)) {
        closeBothMenus();
      }
    });
  };

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (isMainMenuOpen) {
      html.classList.add('is-main-menu-open');
      html.classList.remove('is-account-menu-open');
    } else if (isAccountMenuOpen) {
      html.classList.add('is-account-menu-open');
      html.classList.remove('is-main-menu-open');
      toggleIcons('burger-icon', 'burger-close-icon'); // show burger icon
    } else {
      html.classList.remove('is-main-menu-open', 'is-account-menu-open');
    }
  }, [isMainMenuOpen, isAccountMenuOpen]);

  useEffect(() => {
    if (window.innerWidth < breakpoints.xl && searchRef.current) {
      const observer = new MutationObserver(handleMutation);
      observer.observe(searchRef.current, { attributes: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [searchRef]);

  return (
    <div className='navigation-mobile-item' ref={ref}>
      <button
        onClick={toggleMainMenu}
        className='navigation-menu__toggle navigation-menu__toggle--menu btn--blended'
      >
        <span className='navigation-menu__toggle-icon icon-burger' id='burger-icon' />
        <span
          className='navigation-menu__toggle-icon icon-default-close navigation-menu__icon--hidden'
          id='burger-close-icon'
        />
      </button>
      {sedition.user.isGuest() && <GuestMainMenu open={isMainMenuOpen} />}
      {!sedition.user.isGuest() && <UserMainMenu open={isMainMenuOpen} />}
      <button className='navigation-menu__toggle--search-wrap'>
        <a
          className='navigation-menu__toggle navigation-menu__toggle--search icon-search js-search-toggle'
          href='/search'
          id='search-icon'
        />
        <span
          className='navigation-menu__toggle navigation-menu__toggle--search icon-default-close navigation-menu__icon--no-events navigation-menu__icon--hidden'
          id='search-close-icon'
          ref={searchRef}
        />
      </button>
      <button
        onClick={toggleAccountMenu}
        className='navigation-menu__toggle navigation-menu__toggle--account btn--blended'
      >
        <img
          alt='Avatar'
          className='avatar navigation-menu__avatar'
          src={
            sedition.user.isGuest()
              ? `${CDN_ASSETS_URL}default-avatars/artist_profile.png`
              : sedition.user.get('avatarUrl')
          }
          width='36'
          height='36'
        />
        <span
          className={`navigation-menu__toggle-icon icon-down ${
            isAccountMenuOpen ? 'icon-down--flipped' : ''
          }`}
        />
      </button>
      {sedition.user.isGuest() && <GuestAccountMenu open={isAccountMenuOpen} />}
      {!sedition.user.isGuest() && (
        <UserAccountMenu userUrl={sedition.user.get('url') || ''} open={isAccountMenuOpen} />
      )}
    </div>
  );
};
