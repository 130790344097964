import React from 'react';
import { connect } from 'react-redux';
import values from 'lodash/values';
import findIndex from 'lodash/findIndex';

import * as Types from 'types';
import { SvgIcon } from 'components/icons/SvgIcon';

type StateProps = {
  isLoggedIn: boolean;
  ownsArtwork: boolean;
  isSubscriber: boolean;
};

type OwnProps = {
  artwork: Types.Artwork;
};

type Props = StateProps & OwnProps;

const WatermarkIndicatorComponent: React.FC<Props> = ({
  isLoggedIn,
  ownsArtwork,
  isSubscriber,
  children,
  artwork,
}) => {
  let title = '';
  const artworkIsSoldOut = artwork.soldOut;
  const artworkIsNotForSale = !artwork.forSale;

  if (isLoggedIn) {
    if (ownsArtwork) {
      return null;
    } else {
      if (isSubscriber && !artworkIsSoldOut && !artworkIsNotForSale) {
        return null;
      } else if (artworkIsSoldOut) {
        title =
          'Watermarked artwork because it is sold out and you do not own an edition. You can still buy this artwork from other collectors on Sedition Trade.';
      } else if (artworkIsNotForSale) {
        title =
          'Watermarked artwork because the artwork is no longer for sale and you do not own an edition. You can still buy this artwork from other collectors on Sedition Trade.';
      } else if (!isSubscriber && !artworkIsSoldOut) {
        title =
          'Watermarked artwork. Buy artwork or subscribe to Art Stream to enjoy the artwork without watermark.';
      }
    }
  } else {
    title = 'Watermarked artwork. Please log in to see this artwork without watermark.';
  }

  return (
    <span style={{ cursor: 'help', display: 'inline-block' }} title={title}>
      {children || <SvgIcon icon='watermark' className='watermarked-indicator' />}
    </span>
  );
};

const mapStateToProps = (state: Types.RootState, props: OwnProps): StateProps => {
  const currentUser = state.currentUser.user;
  let ownsArtwork = false;
  let isSubscriber = false;

  if (currentUser) {
    const index = findIndex(
      values(state.editions.byId),
      (edition) => edition.artworkId === props.artwork.id,
    );
    ownsArtwork = index !== -1;

    isSubscriber = currentUser.artStreamActive;
  }

  return {
    isLoggedIn: Boolean(currentUser),
    ownsArtwork,
    isSubscriber,
  };
};

export const WatermarkIndicator = connect(mapStateToProps)(WatermarkIndicatorComponent);
