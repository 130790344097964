import React, { FC, ReactNode } from 'react';

type Props = {
  slogan: string;
  children: ReactNode;
};

export const Headline: FC<Props> = ({ slogan, children }) => (
  <div className='pos-rel mb-2'>
    <h2 className='subscribe-box__slogan mb-mini'>{slogan}</h2>
    <h1 className='subscribe-box__headline'>{children}</h1>
  </div>
);
