import React from 'react';
import classNames from 'classnames';

import * as Types from 'types';
import { ArtworkTitle } from 'components/artworks/ArtworkTitle';

type Props = { artwork: Types.Artwork; user: Types.User } & Types.Styleable;

export const ArtworkTitleWithAvatar: React.FC<Props> = ({
  className,
  artwork,
  user,
}) => (
  <div className={classNames('d-flex', className)}>
    <a href={artwork.artistUrl} className='mr-1' style={{ maxWidth: 71 }}>
      <img
        className='avatar avatar--rounded'
        alt={artwork.artistName}
        src={user.avatarUrl}
      />
    </a>
    <ArtworkTitle
      artwork={artwork}
      style={{ zIndex: 1 }}
      titleLinks
      showEdition
      small
    />
  </div>
);
//s
