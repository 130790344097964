export default [
  {
    artworkId: 'joss',
    deviceType: 'imac',
    background: 'navy',
    objects: [
      {
        name: 'touchbar',
        label: 'Touchbar',
      },
      {
        name: 'paperweight',
        label: 'Paperweight',
      },
      {
        name: 'plant',
        label: 'Plant',
      },
      {
        name: 'crystal',
        label: 'Crystal',
      },
      {
        name: 'rose',
        label: 'Rose',
      },
    ],
  },
  {
    artworkId: 'portrait-e',
    deviceType: 'iphone-x',
    background: 'blue',
    objects: [
      {
        name: 'horse',
        label: 'Horse',
      },
      {
        name: 'balls',
        label: 'Balls',
      },
      {
        name: 'tree',
        label: 'Tree',
      },
      {
        name: 'flower',
        label: 'Flower',
      },
    ],
  },
  {
    artworkId: 'volumes',
    deviceType: 'frame',
    background: 'purple',
    objects: [
      {
        name: 'vase',
        label: 'Vase',
      },
      {
        name: 'goo',
        label: 'Goo',
      },
      {
        name: 'elephant',
        label: 'Elephant',
        className: 'zi-1',
      },
    ],
  },
  {
    artworkId: 'comme-des-organismes',
    deviceType: 'samsung-tv',
    background: 'green',
    objects: [
      {
        name: 'skull',
        label: 'Skull',
      },
      {
        name: 'cactus',
        label: 'Cactus',
      },
      {
        name: 'diamond',
        label: 'Diamond',
        className: 'zi-1',
      },
    ],
  },
];
