import { useRef, useEffect } from 'react';

export const useOutsideClick = (
  element: Element | null,
  onOutsideClick: () => void,
) => {
  const handler = useRef<(event: MouseEvent) => void>();

  useEffect(() => {
    const currentHandler = handler.current;
    if (currentHandler) {
      document.removeEventListener('click', currentHandler);
    }
    handler.current = (event: MouseEvent) => {
      const target = event.target;

      if (target instanceof Element) {
        if (element && element !== target && !element.contains(target)) {
          onOutsideClick();
        }
      }
    };

    if (element !== null) {
      document.addEventListener('click', handler.current);
    }

    return () => {
      const currentHandler = handler.current;
      if (currentHandler) {
        document.removeEventListener('click', currentHandler);
      }
    };
  }, [element, onOutsideClick]);
};
