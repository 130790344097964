/**
 * Various little helpers.
 */

const fibonacci = (num: number): number => (num <= 1 ? 1 : fibonacci(num - 1) + fibonacci(num - 2));

/**
 * Returns Promise that retries given callback until it succeeds.
 *
 * @param callback Function to execute until it resolves
 * @param timesToRetry Time to execute before failing
 * @param retryInterval Interval in ms that retries should take place
 * @param backoff If true period between retries will be multiplied by fibonacci sequence <3
 */
export const retryIfRejected = (
  callback: () => Promise<void>,
  timesToRetry = 10,
  retryInterval = 500,
  backoff = false,
): Promise<void> => {
  let attempt = 0;

  return new Promise((resolve, reject) => {
    const retrier = () => {
      callback()
        .then(resolve)
        .catch((failImmidiatelly) => {
          if (attempt < timesToRetry && !failImmidiatelly) {
            attempt++;
            setTimeout(retrier, retryInterval * (backoff ? fibonacci(attempt) : 1));
          } else {
            reject();
          }
        });
    };

    retrier();
  });
};

export const loadRemoteScript = (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = () => resolve();
    scriptTag.onerror = () => reject();

    document.body.appendChild(scriptTag);
  });
};

export const timeoutPromise = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

// toggles navbar icons
export const toggleIcons = (iconOne: string, iconTwo: string) => {
  const classOne = 'navigation-menu__icon--hidden';
  const classTwo = 'navigation-menu__icon--show';

  const iconOneEl = document.getElementById(iconOne);
  iconOneEl?.classList.remove(classOne);
  iconOneEl?.classList.add(classTwo);

  const iconTwoEl = document.getElementById(iconTwo);
  iconTwoEl?.classList.remove(classTwo);
  iconTwoEl?.classList.add(classOne);
};
