//
// Re-exports untyped constants with manual types
//

// @ts-ignore
import * as Constants from 'constants/backEnd';

export const SEDITION_USER_SLUG = 'sedition';
export const VOUCHER_COOKIE_NAME = 'voucher-code';
export const CDN_ASSETS_URL = Constants.CDN_ASSETS_URL as string;
export const assetUrl = Constants.assetUrl as (path?: string) => string;
export const BLANK_VIDEO_URL = '/blank.m4v';
export const DRAGGABLE_TYPES = {
  PLAYLIST_ARTWORK: 'playlist-artwork',
};
export const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 1171,
};
export const WIDTH_4K = Constants.WIDTH_4K as number;

export type Features =
  | 'sign_in_with_apple'
  | 'support_v2';

export const FEATURES = Constants.FEATURES as Record<Features, boolean>;

export const STRIPE_PUBLISHABLE_KEY = Constants.STRIPE_PUBLISHABLE_KEY as string;
export const ART_STREAM_MONTHLY = Constants.ART_STREAM_MONTHLY as Constants.StripePlan;
export const ART_STREAM_YEARLY = Constants.ART_STREAM_YEARLY as Constants.StripePlan;

export const RECAPTCHA_MIN_REQUIRED_SCORE = Constants.RECAPTCHA_MIN_REQUIRED_SCORE as number;
export const RECAPTCHA2_SITE_KEY = Constants.RECAPTCHA2_SITE_KEY as string;
export const RECAPTCHA3_SITE_KEY = Constants.RECAPTCHA3_SITE_KEY as string;

export const MAX_GIFT_MESSAGE_LENGTH = Constants.MAX_GIFT_MESSAGE_LENGTH as number;

export const HOST = Constants.HOST as string;
export const APPLE_CLIENT_ID = Constants.APPLE_CLIENT_ID as string;
export const WALLET_VERIFY_MESSAGE = Constants.WALLET_VERIFY_MESSAGE as string;
export const CONTRACT_ADDRESS = Constants.CONTRACT_ADDRESS as string;
export const ISSUER_ADDRESS = Constants.ISSUER_ADDRESS as string;
export const CHAIN_ID = parseInt(Constants.CHAIN_ID) as number;
export const OPEN_SEA_URL = Constants.OPEN_SEA_URL as string;

export const FACEBOOK_URL = Constants.FACEBOOK_URL as string;
export const INSTAGRAM_URL = Constants.INSTAGRAM_URL as string;
export const TWITTER_URL = Constants.TWITTER_URL as string;
// TODO: Use BigNumber
export const MINTING_FEE_WEI = String(Constants.MINTING_FEE_WEI) as string;

export const ALCHEMY_API_KEY = Constants.ALCHEMY_API_KEY as string;

export const FLUSH_CACHE_TEST = 2;

export const MOCK_STRIPE = Constants.MOCK_STRIPE as boolean;
