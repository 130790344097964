import React, { FC } from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  light?: boolean;
  size?: 'm' | 's' | 'xs';
};

export const Spinner: FC<Props> = ({ size, light, style, className }: Props) => (
  <div
    className={classNames('square-spin', className, {
      'square-spin--small': size === 's',
      'square-spin--xs': size === 'xs',
      'square-spin--light': light,
    })}
    style={style}
  />
);

export const SpinnerWithLabel: FC<Props & { children: React.ReactNode }> = ({
  children,
  size,
  light,
  style,
}: Props & { children: React.ReactNode }) => (
  <div className='d-flex'>
    <Spinner size={size} light={light} style={style} />
    <div className='key-value key-value__value ml-1' style={{ marginTop: '1px' }}>
      {children}
    </div>
  </div>
);

Spinner.defaultProps = {
  className: '',
  style: {},
  light: false,
  size: 's',
};

SpinnerWithLabel.defaultProps = {
  className: '',
  style: {},
  light: false,
  size: 's',
};
