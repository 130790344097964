import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CDN_ASSETS_URL } from 'constants/global';

const getAsset = (path) => `${CDN_ASSETS_URL}how-it-works/device-slider/${path}`;

const SlideObject = ({ name, label, className }) => (
  <img
    className={classNames(`device-slider__object pos-abs device-slider__item--${name}`, className)}
    src={getAsset(`${name}.png`)}
    alt={label}
  />
);

SlideObject.defaultProps = {
  className: '',
};

SlideObject.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SlideObject;
