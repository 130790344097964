import { request } from 'utils/request';

import * as Types from 'types';

import { ResponseUserCard, mapResponseUserCardToUserCard } from './index';

type Filters = 'ids' | 'artists' | 'at';

export type Props = {
  filter?: Filters[] | Filters;
  ids?: number[];
  at?: string;
  order?: 'date' | 'popular' | 'alphabetical';
  direction?: 'asc' | 'desc';
} & Types.PaginationProps;

type QueryParams = {
  filter: Props['filter'];
  ids: Props['ids'];
  at: Props['at'];
  order?: Props['order'];
  direction?: Props['direction'];
} & Types.PaginationParams;

const propsToQueryParams = (props: Props) => {
  const queryParams: QueryParams = {
    // Filters
    filter: props.filter,
    ids: props.ids,
    at: props.at,
    // Order
    order: props.order,
    direction: props.direction,
    // Pagination
    per_page: props.perPage,
    page: props.page,
  };

  const joinStrings: { [id: string]: ',' | '|' } = {
    state: '|',
  };

  const query: { [id: string]: string } = {};
  Object.keys(queryParams).forEach((key) => {
    // @ts-ignore
    const value = queryParams[key];
    if (value !== undefined) {
      if (Array.isArray(value)) {
        query[key] = value.join(joinStrings[key] || ',');
      } else {
        query[key] = value;
      }
    }
  });

  return query;
};

export const requestUsers = (props: Props): Promise<Types.UserCard[]> => {
  const params = propsToQueryParams(props);

  return request
    .get<ResponseUserCard[]>('/api/internal/users', { params })
    .then(({ data }) => {
      return data.map(mapResponseUserCardToUserCard);
    });
};
