import { RECAPTCHA3_SITE_KEY } from 'constants/global';

import { retryIfRejected } from 'utils/helpers';

declare global {
  interface Window {
    grecaptchaInitialized: boolean | undefined;
  }
}

const ensureGrecaptchaVariable = (): Promise<void> =>
  retryIfRejected(
    () => {
      if (typeof grecaptcha === 'undefined') {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    },
    20,
    200,
  );

const initializeGrecaptcha = (): Promise<void> => {
  if (window.grecaptchaInitialized) {
    return Promise.resolve();
  } else {
    return new Promise((resolve, reject) => {
      ensureGrecaptchaVariable()
        .then(() => {
          grecaptcha.ready(() => {
            window.grecaptchaInitialized = true;
            document.body.classList.add('recaptcha-executed');
            setTimeout(() => {
              document.body.classList.remove('recaptcha-executed');
            }, 20000);
            resolve();
          });
        })
        .catch(reject);
    });
  }
};

export const recaptcha = (action: string): Promise<string> =>
  new Promise((resolve, reject) => {
    initializeGrecaptcha()
      .then(() => {
        const executeResult = grecaptcha.execute(RECAPTCHA3_SITE_KEY, {
          action,
        });
        // Beware result is not a normal Promise, it does not
        // have .catch function it can be void as well.
        if (executeResult && executeResult.then) {
          executeResult.then(resolve);
        } else {
          resolve('');
        }
      })
      .catch(function () {
        bugsnag.notify('Grecaptcha failed to initialize');
        reject();
      });
  });
