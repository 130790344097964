import React from 'react';
import uniqueId from 'lodash/uniqueId';

// @ts-ignore
import { BLANK_VIDEO_URL } from 'constants/global';

type Props = {
  /**
   * We support passing null to avoid ummounting component and
   * destroying JWPlayer instance. Instead if consumer does not temporarily
   * want to show video null value can be passed and player will render blank.
   */
  url: string | null;
  onTime?: (event: jwplayer.TimeParam) => void;
  onFirstFrame?: () => void;
};

type State = {
  playlist: string[];
};

const JWPlayerOptions = {
  repeat: true,
  autostart: true,
  width: '100%',
  height: '100%',
  mute: true,
  controls: false,
  stretching: 'fill',
};

export class ReactJWPlayer extends React.PureComponent<Props, State> {
  player: jwplayer.JWPlayer | undefined;

  ref = React.createRef<HTMLDivElement>();
  componentId = uniqueId('react-jwplayer-');

  timeEventMetrics = {
    count: 0,
    lastPosition: 0,
    average: 0,
  };

  constructor(props: Props) {
    super(props);
    this.state = { playlist: [] };
  }

  setupPlayer() {
    if (!this.player) {
      this.player = jwplayer(this.componentId);
    }

    let file = this.state.playlist[0];
    // When null JWPlayer will show no playable sources so let's load a blank
    // video instead
    if (file === null) {
      file = BLANK_VIDEO_URL;
    }

    const player = this.player.setup({ ...JWPlayerOptions, file });

    player.on('firstFrame', (e) => {
      if (this.props.onFirstFrame) {
        this.props.onFirstFrame();
      }
    });

    // @TODO Find out if .on('time') creates a duplicate listener during
    // second setup.
    player.on('time', (e) => {
      if (this.props.onTime) {
        this.props.onTime(e);
      }

      const { average, count, lastPosition } = this.timeEventMetrics;
      const delta = e.position - lastPosition;

      this.timeEventMetrics.average = (average * count + delta) / (count + 1);
      this.timeEventMetrics.lastPosition = e.position;
      this.timeEventMetrics.count++;
      /**
       * JWPlayer repeat is not a seamless process. Moreover
       * it will make the browser downlaod the video again and again!
       *
       * We cannot do e.position == e.duration because 'time' event is
       * unpredictable so we calculate average firing and then
       * repeat when we are by 3 'time' events till the end of video.
       *
       * Would be nice to add an option for fade in/out for videos
       * that are not looping nicely.
       */
      if (e.position > e.duration - this.timeEventMetrics.average * 3) {
        this.player && this.player.seek(0);
        this.timeEventMetrics = {
          average: 0,
          lastPosition: 0,
          count: 0,
        };
      }
    });
  }

  componentDidMount() {
    this.setupPlayer();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.url !== prevProps.url) {
      this.setupPlayer();
    }
  }

  componentWillUnmount() {
    this.player && this.player.remove();
    delete this.player;
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.url !== state.playlist[0]) {
      return {
        playlist: [props.url],
      };
    } else {
      return null;
    }
  }

  render() {
    return (
      <div
        ref={this.ref}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#000',
        }}
      >
        <div id={this.componentId} />
      </div>
    );
  }
}
