import React, { useState } from 'react';
import { Portal } from 'react-portal';

import { SearchBar } from './SearchBar';
import { toggleIcons } from 'utils/helpers';

export const SearchToggle: React.FC = () => {
  const [showSearch, setShowSearch] = useState(false);

  const closeHandler = () => {
    const searchBar = document.getElementsByClassName('search-bar')[0];
    searchBar.classList.toggle('search-bar--closing');
    setTimeout(() => {
      setShowSearch(false);
      searchBar.classList.toggle('search-bar--closing');
    }, 150);
    toggleIcons('search-icon', 'search-close-icon'); // show search icon
  };

  const openHandler = () => {
    setShowSearch(true);
    toggleIcons('search-close-icon', 'search-icon'); // show close icon
  };

  return (
    <div
      className='stretch'
      onClick={(event) => {
        if (event.target == event.currentTarget) {
          event.preventDefault();
          event.stopPropagation();
          showSearch ? closeHandler() : openHandler();
        }
      }}
    >
      {showSearch ? (
        <Portal node={$('.js-search-bar')[0]}>
          <SearchBar className='search-bar' onRequestClose={closeHandler} />
        </Portal>
      ) : null}
    </div>
  );
};
