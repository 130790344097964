/**
 * Usage:
 *   <div id="test" data-countdown="10" data-on-complete="hello"></div>
 *   countdown($('#test'))
 *
 *   Will countdown from 10 to 0, then trigger hello event on document.
 */

const pad = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};

export const countdown = ($el: JQuery) => {
  let secondsRemaining = parseFloat($el.data('countdown'));
  const eventName = $el.data('on-complete');

  if (isNaN(secondsRemaining) || !isFinite(secondsRemaining)) {
    return;
  }

  const countdownInterval = setInterval(function () {
    const days = Math.floor(secondsRemaining / (3600 * 24)),
      hours = Math.floor((secondsRemaining - days * 24 * 3600) / 3600),
      minutes = Math.floor(
        (secondsRemaining - days * 24 * 3600 - hours * 3600) / 60,
      ),
      seconds =
        secondsRemaining - days * 24 * 3600 - hours * 3600 - minutes * 60;

    secondsRemaining--;

    if (days) {
      $el.html(`${days} days ` + [hours, minutes, seconds].map(pad).join(':'));
    } else {
      $el.html([hours, minutes, seconds].map(pad).join(':'));
    }

    $el.attr('data-countdown', secondsRemaining);

    if (-1 == secondsRemaining) {
      clearInterval(countdownInterval);

      if (eventName) {
        $(document).trigger(eventName, $el);
      }
    }
  }, 1000);
};
