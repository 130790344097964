import { StateType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { rootReducer } from 'store/rootReducer';

export type RootState = StateType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

export type Identifiable = {
  id: number;
};

export type ById<T> = {
  [id: string]: T;
};

export type ByNumericId<T> = {
  [id: number]: T;
};

export type Styleable = {
  style?: React.CSSProperties;
  className?: string;
};

export type DefaultThunkAction<R = void> = ThunkAction<R, RootState, undefined, AnyAction>;

export type DispatchThunkAction<R = void> = ThunkAction<R, undefined, undefined, AnyAction>;

// Used in JS API
export type PaginationProps = {
  perPage?: number;
  page?: number;
};

// Used in URLs
export type PaginationParams = {
  per_page?: number;
  page?: number;
};

export type List<T> = {
  readonly id: string;
  readonly ids: number[];
  readonly initial: boolean;
  readonly all: boolean;
  readonly isFetching: boolean;
  readonly isRefreshing: boolean;
  readonly type?: 'Artwork';
  objects?: T[];
};

export type ListItem = {
  readonly id: number;
  readonly cacheKey: string;
};

export type PageResponse<DATA> = {
  readonly count: number;
  readonly data: DATA;
};

export enum ArtworkType {
  video = 'video',
  image = 'image',
}

export enum AuctionStates {
  draft = 'draft',
  upcoming = 'upcoming',
  ongoing = 'ongoing',
  ended = 'ended',
}
export type ArtworkResolution = {
  width: number;
  height: number;
};

export enum ArtworkResolutionLabel {
  '4K' = '4K',
  HD = 'HD',
}

export type Artwork = {
  readonly id: number;
  readonly slug: string;
  readonly title: string;
  readonly thumbnailUrl: string;
  readonly artistUserId: number;
  readonly artistName: string;
  readonly childrenCount: number;
  readonly type: ArtworkType;
  readonly thumbnailType: 'artwork';
  readonly dominantColor: string;
  readonly availableForPurchase: boolean;
  readonly soldOut: boolean;
  readonly forSale: boolean;
  readonly url: string;
  readonly artistUrl: string;
  readonly price?: number;
  readonly totalEditions?: number;
  readonly remainingEditions: number;
  readonly auctionState?: AuctionStates;
  readonly nft: boolean;
  readonly vip: boolean;
  readonly giftable: boolean;
};

export type ArtworkDetails = Artwork & {
  readonly bundledPhysicalObjects: BundledPhysicalObject[];
  readonly children: Artwork[];
  readonly bundles: Bundle[];
  readonly description: string;
  readonly categories: Tag['name'][];
  readonly remainingEditions: number;
  readonly auction?: Auction;
  readonly resolution?: ArtworkResolution;
  readonly publishedAt?: Date;
  readonly duration?: number;
};

export type ArtworkWithUser = Artwork & { user: User };

export type ArtworkGroup = {
  id: number;
  slug: string;
  title: string;
};

export type Artist = {
  readonly id: number;
  readonly name: string;
  readonly avatar_url: string;
  readonly url: string;
  readonly editions_count: number;
  readonly followers_count: number;
  readonly following_count: number;
  readonly thumbnailType: 'artist';
};

type BundledPhysicalObject = {
  title: string;
  images: {
    large: string;
  }[];
};

export type Bundle = {
  title: string;
  description: string;
  deliveryPrice: number;
};

export const isArtworkWithDetails = (
  artwork: Artwork | ArtworkDetails,
): artwork is ArtworkDetails => {
  return (artwork as any).description !== undefined;
};

export type PlaylistArtwork = {
  id: string;
  position: number;
  artwork: Artwork;
  placeholder?: boolean;
  editionId?: number;
  edition?: Edition;
};

type DraggableMeta = {
  beginDragBounds: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
};

export type DraggedPlaylistArtwork = PlaylistArtwork & {
  _meta: DraggableMeta;
};

export const isPlaylistArtworkDraggable = (
  playlistArtwork: DraggedPlaylistArtwork,
): playlistArtwork is DraggedPlaylistArtwork => typeof playlistArtwork._meta !== 'undefined';

export const PLAYLIST_TYPES = ['Regular', 'Master', 'Editions'] as const;
export type PlaylistTypes = typeof PLAYLIST_TYPES[number];

export type Playlist = {
  id: string;
  name: string;
  type: PlaylistTypes;
  items: PlaylistArtwork[];
  public: boolean;
  userId?: number; // Undefined means not-loaded or not-available
  description?: string;
};

export type Certificate = {
  id: number;
  imageUrl: string;
  updatedAt: number;
  purchasePrice: number;
  transactionHash?: string;
  ownerAddress?: string;
};

export type Edition = {
  id: number;
  editionNumber: number;
  artworkId: number;
  certificate: Certificate;
};

export type EditionWithArtwork = Edition & {
  artwork: Artwork;
};

export type ArtistCard = {
  readonly artworksCount: number;
  readonly collectorsCount: number;
};

// LoggedInUser < User < UserCard
export type UserCard = {
  readonly id: number;
  readonly name: string;
  readonly avatarUrl: string;
  // When user has private purchases preference, editionsCount is undefined
  readonly editionsCount?: number;
  readonly followingCount: number;
  readonly followersCount: number;
  readonly artist?: ArtistCard;
  readonly url?: string;
};

export type User = UserCard & {
  readonly location?: string;
  readonly biography?: string;
  readonly rank: number;
};

export type LoggedInUser = User & {
  readonly editionsCount: number;
  readonly followingCount: number;
  readonly followersCount: number;
  readonly artStreamActive: boolean;
  readonly invitedUsersCount: number;
  readonly referralCreditsEarned: number;
  readonly inviteCodeIos: string;
  readonly accessToken: string;
  readonly followedUsers: number[];
  readonly followedPlaylists: number[];
  readonly walletAddress?: string;
};

export type Wish = {
  readonly id: number;
  readonly artwork: Artwork;
};

export type PlayerOptions = {
  single?: boolean;
};

export type LegacyArtwork = {
  id: number;
  short_name: string;
  children_count: number;
  dominant_color: string;
  price: number | undefined;
  seconds: number | undefined;
  thumbnail_max: string;
  extended_preload: boolean;
  type: Artwork['type'];
  artist_name: string;
  title: string;
  artist_user_id: number;
  artist_url: string;
  artist_avatar_url: string;
  description: string;
  url: string;
  version: string;
  sold_out: number;
  current_prices: {
    USD: string;
    EUR: string;
    GBP: string;
    CNY: string;
  };
  total_editions: number;
  available_for_purchase: boolean;
  for_sale: number;
  popular: number;
  has_selling_edition_listings: boolean;
  auction_state?: AuctionStates;
  nft: boolean;
  isFull?: boolean;
  vip: boolean;
  not_giftable?: boolean;
};

export type LegacyArtworkDetail = {
  videos?: {
    adaptive: string;
    hd: string;
  };
  images: {
    fullscreen: string;
    hd: string;
  };
} & LegacyArtwork;

export type LegacyPlaylistArtwork = {
  id: string;
  artwork: LegacyArtwork;
};

export type LegacyPlaylist = {
  id: string;
  playlist_artworks: LegacyPlaylistArtwork[];
};

export const isLegacyPlaylist = (
  artwork: LegacyArtworkDetail | LegacyPlaylist,
): artwork is LegacyArtworkDetail => {
  // @ts-ignore
  return artwork.playlist_artworks === undefined;
};

export type Media = {
  artworkId: number;
  url: string;
  expiresAt: number | null;
};

export type IntentKey = 'stripe_checkout';

/**
 * This is most likely not true. We need to convert player to TS to find out
 * how it's data model really looks like.
 */
export type PlayerArtwork = {
  [id: string]: any;
  id: number;
  duration: string;
  thumbnail_max?: string;
  thumbnail: string;
  extended_preload: boolean;
  type: 'image' | 'video';
  title: string;
  artist_name: string;
};

/**
 * @TODO:
 * Lets fix this once we move homepage slides data to API
 */
export interface HomePageSlide {
  id: number;
  image: string;
  label: string | null;
  title: string;
  subtitle: string;
  link_text: string;
  content_position: 'Bottom' | 'Middle';
  show_overlay: 1 | 0;
  background_color: string;
  link: string;
  content_color: string;
  artwork_video_url: string | null;
  artwork_image_url: string | null;
  menu_color: 'Light' | 'Dark';
  artwork: (Artwork & ArtworkDetails & { artist_name: string; total_editions: number }) | null;
}

export const ARTIST_TYPES = ['curated', 'op'] as const;
export type ArtistTypes = typeof ARTIST_TYPES[number];

export type Tag = {
  name: string;
  count: number;
};

export type TagOption = {
  value: string;
  label: string;
};

export type TagType = 'category';

export type BidState =
  | 'unauthorized'
  | 'new'
  | 'replaced'
  | 'matched'
  | 'error'
  | 'paid'
  | 'lost'
  | 'cancelled';

export type Bid = {
  id: number;
  priceCents: number;
  createdAt: number;
  user: UserCard;
  state: BidState;
};

export type Auction = {
  id: number;
  startsAt: number;
  endsAt?: number;
  winningBidId?: number;
};

export type FollowableType = 'User' | 'Playlist';

export enum KEYS {
  BACKSPACE = 8,
  ESCAPE = 27,
  SPACE = 32,
  ARROW_LEFT = 37,
  ARROW_UP = 38,
  ARROW_RIGHT = 39,
  ARROW_DOWN = 40,
}

export type Listing = {
  id: string;
  title:
    | 'Recommended'
    | 'Best Sellers'
    | 'World Renowned Artists'
    | 'New Launches'
    | 'Slow or Still'
    | 'NFTs';
  position: number;
};

export type Action = {
  id: number;
  body?: string;
  artworkId: number;
  userCard: UserCard;
  timestamp?: number;
};

export type CreditCard = {
  name?: string;
  brand: string;
  expiresMonth: number;
  expiresYear: number;
  last4: number;
};

export type PaymentMethod = {
  id: string;
  card: CreditCard;
  address: {
    line1?: string;
    line2?: string;
    postalCode?: string;
    state?: string;
    city?: string;
    countryCode?: string;
  };
  default: boolean;
};

/**
 * Renderable Error
 */

// Internal type
export type RenderableError<TErrorCode> = {
  message: string;
  errors: string[];
  redirectTo?: string;
  errorCode?: TErrorCode;
  errorVariable?: any;
};

// Response
export type RenderableErrorResponse<TErrorCode> = {
  error: string;
  errors: string[];
  redirect_to?: string;
  error_code?: TErrorCode;
  error_variable?: any;
};

export type AxiosRenderableErrorResponse<T> = AxiosResponse<RenderableErrorResponse<T>>;

export type ApiResult<T, ErrorType = any> =
  | {
      data: T;
      error?: undefined;
    }
  | {
      data?: undefined;
      error: RenderableError<ErrorType>;
    };

// Chromecast actions
export enum ChromecastActions {
  SET_RECEIVER_SETTINGS = 'SET_RECEIVER_SETTINGS',
  PLAYLIST_SET_REPEAT_MODE = 'PLAYLIST_SET_REPEAT_MODE',
  PLAYLIST_TRANSITION_START = 'PLAYLIST_TRANSITION_START',
  SET_MUTE_STATE = 'SET_MUTE_STATE',
}

// Chromecast Settings
export enum ScreenOrientationOptions {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}

export enum ShowTitleOptions {
  ON = 'ON',
  OFF = 'OFF',
  WITH_TIMEOUT = 'WITH_TIMEOUT',
}
