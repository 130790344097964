import React, { FC } from 'react';

type Props = {
  id: string;
  label: string;
  checked: boolean;
  readOnly?: boolean;
  onChange?: (
    checked: boolean,
    event: React.SyntheticEvent<HTMLInputElement>,
  ) => void;
};

export const Toggle: FC<Props> = ({
  id,
  checked,
  readOnly,
  onChange,
  label,
}) => {
  return (
    <label className='switch' htmlFor={id} aria-label={label}>
      <input
        id={id}
        tabIndex={readOnly ? -1 : 0}
        type='checkbox'
        checked={checked}
        readOnly={readOnly}
        onChange={(e) => onChange?.(e.target.checked, e)}
      />
      <div className='slider'></div>
    </label>
  );
};
