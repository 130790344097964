export type Currency = 'USD' | 'GBP' | 'EUR' | 'CNY';

const CURRENCY_SYMBOL_MAP = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  CNY: '¥',
};

export const formatPrice = (price: string | number = 0, currency: Currency = 'USD') => {
  const symbol = CURRENCY_SYMBOL_MAP[currency];
  if (typeof price === 'string') {
    return `${symbol}${Math.round(parseInt(price, 10) / 100)}`;
  } else {
    return `${symbol}${Math.round(price / 100)}`;
  }
};

const getExchangeRate = (currency: Currency) => {
  const defaultExchangeRates = {
    EUR: 0.898527,
    CNY: 6.9729,
    GBP: 0.749372,
    USD: 1,
  };

  let exchangeRates = defaultExchangeRates;

  if (window.sedition.exchangeRates) {
    if (window.sedition.exchangeRates.GBP) {
      exchangeRates.GBP = window.sedition.exchangeRates.GBP;
    }
    if (window.sedition.exchangeRates.EUR) {
      exchangeRates.EUR = window.sedition.exchangeRates.EUR;
    }
    if (window.sedition.exchangeRates.CNY) {
      exchangeRates.CNY = window.sedition.exchangeRates.CNY;
    }
  }

  return exchangeRates[currency];
};

export const formatCurrentUserPrice = (price: string | number = 0) =>
  formatPrice(price, sedition.currentUser.currency);

export const formatArtworkPrice = (price: number | undefined) => {
  if (price === undefined) {
    return '';
  }

  switch (sedition.currentUser.currency) {
    case 'EUR':
      return formatPrice(price * getExchangeRate('EUR'), 'EUR');
    case 'GBP':
      return formatPrice(price * getExchangeRate('GBP'), 'GBP');
    case 'CNY':
      return formatPrice(price * getExchangeRate('CNY'), 'CNY');
    default:
      return formatPrice(price, 'USD');
  }
};

export const formatNumber = (num: string) =>
  num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const formatDuration = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter((a) => a).join(':');
};
