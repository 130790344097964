import React from 'react';

import * as Types from 'types';

const IconMap: Record<string, string> = {
  close:
    'M29.333 8l-5.333-5.333-8 8-8-8-5.333 5.333 8 8-8 8 5.333 5.333 8-8 8 8 5.333-5.333-8-8z',
  play: 'M10.654 22.588v-13.176l10.942 6.635z',
  search:
    'M28.072 24.748l-6.046-6.046c0.912-1.5 1.436-3.256 1.436-5.14 0-5.466-4.738-10.204-10.204-10.204s-9.898 4.432-9.898 9.898c0 5.468 4.736 10.204 10.204 10.204 1.818 0 3.52-0.492 4.984-1.348l6.078 6.080c0.596 0.596 1.56 0.596 2.154 0l1.508-1.508c0.594-0.596 0.378-1.344-0.216-1.938zM6.406 13.258c0-3.784 3.068-6.852 6.852-6.852 3.786 0 7.158 3.372 7.158 7.158s-3.068 6.852-6.852 6.852-7.156-3.372-7.156-7.158z',
};

type Props = {
  icon: string;
} & Types.Styleable;

export const Icon = (props: Props) => {
  const className = `${props.className || ''} svg-icon react-icon-${
    props.icon
  }`;

  return (
    <svg
      className={className}
      style={props.style}
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
    >
      <path d={IconMap[props.icon]}></path>
    </svg>
  );
};

export default Icon;
