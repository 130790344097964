import React, { FC } from 'react';

import { PopupPlayer } from 'components/PopupPlayer';

const getLinkByType = (type: 'vimeo' | 'youtube', videoId: string) => {
  switch (type) {
    case 'vimeo':
      return `https://player.vimeo.com/video/${videoId}?color=FF2850&title=0&byline=0&portrait=0&autoplay=1`;
    case 'youtube':
      return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
    default:
      throw 'Unsupported';
  }
};

type Props = {
  title?: string;
  videoId: string;
  type: 'youtube' | 'vimeo';
};

export const ModalVideoPlayer: FC<Props> = ({ type, videoId, title = '' }) => (
  <PopupPlayer>
    <iframe
      style={{ width: '100%', height: '100%' }}
      src={getLinkByType(type, videoId)}
      title={title}
      frameBorder='0'
      allowFullScreen
    />
  </PopupPlayer>
);
