import React from 'react';
import ReactDOM from 'react-dom';

import { SearchToggle } from 'components/search/SearchToggle';

export const GlobalSearchBar = () => {
  $('.js-search-toggle').each(function () {
    ReactDOM.render(<SearchToggle />, this);
  });
};
