import React from 'react';

import { assetUrl } from 'constants/global';

type Props = {
  type: 'imac' | 'iphone-x' | 'frame' | 'samsung-tv' | 'lg-tv';
  style?: React.CSSProperties;
};

export const Device: React.FC<Props> = ({ type, style, children }) => (
  <div className={`device device--${type}`} style={style}>
    <div className='device__content-container'>
      <div className='device__content'>{children}</div>
    </div>
    <img
      className='pos-rel zi-1'
      src={assetUrl(`devices/${type}-1.png`)}
      alt={type}
    />
  </div>
);
