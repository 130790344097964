/**
 * Utility for pushing information to dataLayer on click.
 */

import $ from 'jquery';

export const GlobalTracking = (selector) => {
  $(selector).each(function () {
    const $el = $(this);

    /**
     * 1. Make sure multiple events are not created
     *    when GlobalTracking() is being called twice with same
     *    selector.
     * 2. Use DOM attribute to keep track if element was already
     *    initialized. Works better than .data() which can only
     *    be used with jQuery.
     */
    if ($el.attr('data-layer-tracking') === undefined) {
      $el.attr('data-layer-tracking', '');
      $el.on('click', () => {
        const layer = $el.data('layer');
        if (layer) {
          dataLayer.push(layer);
        }
      });
    }
  });
};
