import React, { FC, useCallback, useEffect, createRef, useRef } from 'react';
import Tooltip from 'tooltip.js';
import classNames from 'classnames';

import * as Types from 'types';

type Props = {
  title?: string;
  placement?: 'auto' | 'top' | 'bottom' | 'left' | 'right';
  visible?: boolean;
  trigger?: 'click' | 'hover' | 'focus' | 'manual';
  delay?: number;
  closeOnClickOutside?: boolean;
} & Types.Styleable;

export const ComposingTooltip: FC<Props> = ({
  title,
  placement,
  visible = true,
  trigger,
  delay,
  closeOnClickOutside,
  ...props
}) => {
  const tooltipDOMElementRef = createRef<HTMLElement>();
  const tooltipRef = useRef<Tooltip>();

  useEffect(() => {
    tooltipDOMElementRef;

    if (tooltipDOMElementRef.current) {
      if (visible) {
        tooltipRef.current = new Tooltip(tooltipDOMElementRef.current, {
          container: document.body,
          placement: placement || 'top',
          title,
          trigger: trigger || 'hover',
          delay: delay || 0,
          closeOnClickOutside: closeOnClickOutside || true,
          template: `<div class="tooltip" role="tooltip" data-placement="${placement || 'top'}">
          <div class="tooltip__arrow"></div>
          <div class="tooltip__inner"></div>
          </div>`,
        });
      } else {
        tooltipRef.current?.dispose();
      }
    }

    return () => tooltipRef.current?.dispose();
  }, [visible]);

  useEffect(() => {
    if (tooltipRef.current && title) {
      tooltipRef.current.updateTitleContent(title);
    }
  }, [title]);

  // Handle visibility changes based on the 'visible' prop for manual trigger
  useEffect(() => {
    if (tooltipRef.current) {
      if (visible && trigger === 'manual') {
        tooltipRef.current.show();
      } else {
        tooltipRef.current.hide();
      }
    }
  }, [visible]);

  return (
    <span className={classNames(props.className)} ref={tooltipDOMElementRef} style={props.style}>
      {props.children}
    </span>
  );
};

type ComposingTooltipRenderProps<T> = {
  children: (setRef: (el: T) => void) => JSX.Element;
} & Props;

export function ComposingTooltipRenderProps<T>({
  title,
  placement,
  visible = true,
  children,
  ...props
}: ComposingTooltipRenderProps<T>) {
  const tooltipDOMElementRef = useRef<HTMLElement | null>(null);
  const tooltipRef = useRef<Tooltip>();

  useEffect(() => {
    const { current } = tooltipDOMElementRef;

    if (current) {
      if (visible) {
        tooltipRef.current = new Tooltip(current, {
          container: document.body,
          placement: placement || 'top',
          title,
          template: `<div class="tooltip" role="tooltip" data-placement="${placement || 'top'}">
          <div class="tooltip__arrow"></div>
          <div class="tooltip__inner"></div>
          </div>`,
        });
      } else {
        tooltipRef.current?.dispose();
      }
    }

    return () => tooltipRef.current?.dispose();
  }, [visible, tooltipDOMElementRef.current]);

  useEffect(() => {
    const { current } = tooltipRef;
    if (current && title) {
      current.updateTitleContent(title);
    }
  }, [title]);

  const setRef = useCallback((el) => {
    tooltipDOMElementRef.current = el;
  }, []);

  return children(setRef);
}

export const withTooltip =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P & Props> =>
  ({ title, placement, visible = true, ...props }) => {
    const tooltipDOMElementRef = createRef<HTMLDivElement>();
    const tooltipRef = useRef<Tooltip>();

    useEffect(() => {
      const { current } = tooltipDOMElementRef;

      if (current) {
        if (visible) {
          tooltipRef.current = new Tooltip(current, {
            container: document.body,
            placement: placement || 'top',
            title,
            template: `<div class="tooltip" role="tooltip" data-placement="${placement || 'top'}">
          <div class="tooltip__arrow"></div>
          <div class="tooltip__inner"></div>
          </div>`,
          });
        } else {
          tooltipRef.current?.dispose();
        }
      }

      return () => tooltipRef.current?.dispose();
    }, [visible]);

    useEffect(() => {
      const { current } = tooltipRef;
      if (current && title) {
        current.updateTitleContent(title);
      }
    }, [title]);

    return (
      <Component
        className={classNames(props.className)}
        ref={tooltipDOMElementRef}
        style={props.style}
        {...(props as P)}
      />
    );
  };
