import Editor from 'editor/editor';
import MentionBlot from 'editor/quill/formats/mention';

class PostForm {
  constructor(el) {
    this.$el = $(el);
    this.editor = new Editor(el.querySelector('.js-editor'));
    this.$el.on('submit.post-form', this.submit.bind(this));
  }

  submit(e) {
    // Set editor contents
    this.$el.find('.js-content-input').val(this.editor.getContents());

    // Extract mentions and pass them as JSON
    let mentions = MentionBlot.getInstances(this.editor.quill);
    mentions = mentions.map((mention) => ({
      mentionable_id: mention,
      mentionable_type: 'user',
    }));

    const $mentionsInput = this.$el.find('.js-mentions-input');
    $mentionsInput.val(JSON.stringify(mentions));
  }
}

export { PostForm };
