import React from 'react';

import { ConfirmationModal, Props } from './ConfirmationModal';

export const ErrorModal: React.FC<Props> = (props) => {
  return (
    <ConfirmationModal
      {...props}
      title={props.title || 'Error'}
      dangerLabel={props.dangerLabel || 'Ok'}
      cancel={props.cancel || false}
      // We show only OK button which should so same as cancel.
      onConfirm={props.onRequestClose}
    >
      {props.children}
    </ConfirmationModal>
  );
};
